import React, {
    createContext, ReactNode, useState, useMemo,
} from 'react';
import { EstimateType } from '../../types/EstimateType';

interface Props {
    children: ReactNode
}

export interface EstimateTypeExpanded extends EstimateType {
    isExpanded: boolean;
}

export interface LevelState {
    values?: EstimateTypeExpanded[];
    expanded?: boolean;
    total?: number;
}

export interface LevelsRef {
    [key: string]: LevelState;
}

export interface RowGroup {
    [key: string]: any;
}

export interface ValueCols {
    [key: string]: any;
}

interface UnitRateContextType {
  unitRateId: string | null;
  setUnitRateId: (unitRateId: string | null) => void;
  levelsData: LevelsRef;
  setLevelsData: (levelsData: LevelsRef) => void;
  rowData: RowGroup[];
  setRowData: (rowData: RowGroup[]) => void;
  valueColsData: ValueCols[];
  setValueColsData: (valueColsData: ValueCols[]) => void;
}

const UnitRateContext = createContext<UnitRateContextType>({
    unitRateId: null,
    setUnitRateId: () => {},
    levelsData: {},
    setLevelsData: () => {},
    rowData: [],
    setRowData: () => [],
    valueColsData: [],
    setValueColsData: () => [],
});

function UnitRateContextProvider(props: Props) {
    const { children } = props;
    const [unitRateId, setUnitRateId] = useState<string | null>(null);
    const [levelsData, setLevelsData] = useState<LevelsRef>({});
    const [rowData, setRowData] = useState<RowGroup[]>([]);
    const [valueColsData, setValueColsData] = useState<ValueCols[]>([]);

    const defaultValue = useMemo(() => ({
        unitRateId,
        setUnitRateId,
        levelsData,
        setLevelsData,
        rowData,
        setRowData,
        valueColsData,
        setValueColsData,
    }), [unitRateId, levelsData, rowData, valueColsData]);

    return (
        <UnitRateContext.Provider value={defaultValue}>
            {children}
        </UnitRateContext.Provider>
    );
}

export { UnitRateContext };

export default UnitRateContextProvider;
