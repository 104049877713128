import React, { useContext, useEffect, useState } from 'react';
import {
    Box, Typography, DialogContent, DialogTitle,
    Dialog, Grid, Button, TextField, DialogActions, FormControl, Select, MenuItem, SelectChangeEvent, FormHelperText, InputLabel,
} from '@mui/material';
import SaveNewReportModelStyle from './SaveNewReportModel.style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReportType } from '../../../../types/Reports';
import { useLocation, useOutletContext } from 'react-router-dom';
import { PATH_DATABASE, PATH_CMFR } from '../../../../Routes/path';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import reportQuery from '../../../../queries/report';
import { QueryErrorResponse } from '../../../../types/CommoditiesListType';
import { OutletContext } from '../../../../types/OrganisationDetailType';
import { UserContext } from '../../../../store/context/userContext';
import Loader from '../../../../components/Loader';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';

const MODULE_FILTER_ORGANISATIONS = [
    { label: 'Dashboard', value: 'dashboard' },
    { label: 'Industries', value: 'industries' },
    { label: 'Commodities', value: 'commodities' },
    { label: 'Projects', value: 'projects' },
];

const PROJECT_MODULE_FILTER_ORGANISATIONS = [
    { label: 'Project Dashboard', value: 'project dashboard' },
    { label: 'WBS', value: 'wbs' },
    { label: 'COA', value: 'coa' },
    { label: 'Packages', value: 'packages' },
    { label: 'Estimate', value: 'estimate' },
    { label: 'Unit Rate', value: 'unit rate' },
    { label: 'Resources', value: 'resources' },
    { label: 'Schedule', value: 'schedule' },
    { label: 'Project Settings', value: 'project settings' },
];

// const ORGANISATION_MODULES = ['dashboard', 'industries', 'commodities', 'projects'];
// const PROJECT_MODULES = ['project dashboard', 'wbs', 'coa', 'packages', 'estimate', 'unit rate', 'resources', 'schedule', 'project settings'];

// const isValidModule = (pathname: string, moduleName: string) => {
//     if (pathname.includes(PATH_CMFR.capex.reports)) {
//         return PROJECT_MODULES.includes(moduleName);
//     }

//     return ORGANISATION_MODULES.includes(moduleName);
// };

interface SaveNewReportModelProps {
    open: boolean,
    setOpen: (isOpen: boolean) => void,
    setOpenReportPopup: (isOpen: boolean) => void,
    setReportDetails: (details: ReportType) => void,
    isEditMode: boolean,
    selectedReport: ReportType | null,
    selectedModule: string | null,
    isDuplicateMode: boolean,
    reportListUpdate: (isEditMode: boolean) => void,
    setEditMode: (isEditMode: boolean) => void,
    setIsDuplicateMode: (isDuplicateMode: boolean) => void,
}

function SaveNewReportModel({
    open,
    setOpen,
    setOpenReportPopup,
    setReportDetails,
    isEditMode,
    selectedReport,
    selectedModule,
    isDuplicateMode,
    reportListUpdate,
    setEditMode,
    setIsDuplicateMode,
}: SaveNewReportModelProps) {
    const classes = SaveNewReportModelStyle();
    const [module, setModule] = useState('');
    const [name, setName] = useState('');
    const [mandatoryErrors, setMandatoryErrors] = useState({
        name: '',
        module: '',
    });
    const { pathname, search } = useLocation();
    const query = new URLSearchParams(search);
    // const moduleFilter = query.get('module');
    const cont: OutletContext = useOutletContext();
    const userCtx = useContext(UserContext);
    const orgID = userCtx?.user?.default_org_id;

    useEffect(() => {
        // if (moduleFilter && isValidModule(pathname, moduleFilter)) {
        // Check if passed module name is valid and filter as per module
        // const filterMenuItems = pathname.includes(PATH_DATABASE.reports) ? MODULE_FILTER_ORGANISATIONS : PROJECT_MODULE_FILTER_ORGANISATIONS;
        // const moduleSelected = filterMenuItems.find((item) => item.value === moduleFilter);
        // if (moduleSelected) {
        // }
        // }
        // Above condition is not required since we select the module based on dropDown selection
        setModule(selectedModule as string);

        // Prefill values for edit mode
        if (isEditMode) {
            setName(selectedReport?.display_name || '');
            setModule(selectedReport?.module_name || '');
        }

        // Prefill module for duplicate mode
        if (isDuplicateMode) {
            setModule(selectedReport?.module_name || '');
        }
    }, []);

    const handleChange = (event: SelectChangeEvent) => {
        setMandatoryErrors({
            ...mandatoryErrors,
            module: '',
        });
        setModule(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
        setName('');
        setModule('');
        setMandatoryErrors({
            name: '',
            module: '',
        });
        setEditMode(false);
        setIsDuplicateMode(false);
    };

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const handleApiSuccess = () => {
        let successMessage = 'Report saved successfully.';
        if (isEditMode) {
            successMessage = 'Report updated successfully.';
        } else if (isDuplicateMode) {
            successMessage = 'Report duplicated successfully.';
        }
        cont.showNotificationBar(successMessage, 'success');
        reportListUpdate(false);
        setOpen(false);
        if (isDuplicateMode) {
            setIsDuplicateMode(false);
        } else if (isEditMode) {
            setEditMode(false);
        }
    };

    const handleReportNameValidationSuccess = () => {
        setOpenReportPopup(true);
        setReportDetails({
            display_name: name,
            module_name: module,
            id: '',
            data: '',
        });
        setOpen(false);
    };

    const { mutate: renameMutate, data: editData, isLoading: isUpdating } = useGQLMutation(
        'UpdateReport',
        reportQuery.RENAME_REPORT,
        {
            onSuccess: handleApiSuccess,
            onError: handleApiError,
        },
        '/list',
    );

    const { mutate, data, isLoading } = useGQLMutation(
        'CreateReport',
        reportQuery.ADD_REPORT,
        {
            onSuccess: handleApiSuccess,
            onError: handleApiError,
        },
        '/list',
    );

    const { data: validateData, isFetching: isValidating, refetch } = useGQLQuery(
        '',
        reportQuery.VALIDATE_REPORT_NAME(
            orgID || '',
            name,
        ),
        {},
        {
            onSuccess: handleReportNameValidationSuccess,
            onError: handleApiError,
            enabled: false,
        },
    );

    const handleSave = () => {
        // Mandatory check for name and module field
        if (!module || !name || !(name.length > 2)) {
            let nameError = name ? '' : 'Mandatory field';
            if (name && !(name.length > 2)) {
                nameError = 'Minimum 3 characters required';
            }
            setMandatoryErrors({
                name: nameError,
                module: module ? '' : 'Mandatory field',
            });
        } else if (isDuplicateMode) {
            // Call create mutation of report
            mutate({
                org_id: orgID,
                data: selectedReport?.data,
                module_name: module,
                display_name: name,
            });
        } else if (isEditMode) {
            setOpenReportPopup(true);
            setReportDetails({
                display_name: name,
                module_name: module,
                id: '',
                data: '',
            });
            setOpen(false);
        } else {
            // Check report name validation
            refetch();
        }
    };

    const handleSaveAs = () => {
        // Mandatory check for name and module field
        if (!module || !name) {
            setMandatoryErrors({
                name: name ? '' : 'Mandatory field',
                module: module ? '' : 'Mandatory field',
            });
        } else {
            renameMutate({
                org_id: orgID,
                id: selectedReport?.id,
                display_name: name,
                module_name: module,
            });
        }
    };

    const filterMenuItems = pathname.includes(PATH_DATABASE.reports) ? MODULE_FILTER_ORGANISATIONS : PROJECT_MODULE_FILTER_ORGANISATIONS;
    let reportTitle = 'Save New Report';
    if (isEditMode) {
        reportTitle = 'Edit Report';
    } else if (isDuplicateMode) {
        reportTitle = 'Duplicate Report';
    }

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxSection}>{reportTitle}</DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    { isUpdating || isLoading || isValidating ? <Loader loading={isUpdating || isLoading || isValidating} isFromAGGrid /> : null }
                    <Grid container spacing={2} className={classes.sectionBody}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Select Module
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }} error size="small">
                                <Select
                                    value={module}
                                    onChange={handleChange}
                                    displayEmpty
                                    IconComponent={KeyboardArrowDownIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    fullWidth
                                    error={!!mandatoryErrors.module}
                                    // disabled={!!(isEditMode || isDuplicateMode || (moduleFilter && isValidModule(pathname, moduleFilter)))}
                                >
                                    {filterMenuItems.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                                </Select>
                                { mandatoryErrors.module ? <FormHelperText>{mandatoryErrors.module}</FormHelperText> : null }
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Report Name
                            </Typography>
                            <TextField
                                fullWidth
                                color="secondary"
                                placeholder="Type Name..."
                                id="name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value.trimStart());
                                    setMandatoryErrors({
                                        ...mandatoryErrors,
                                        name: '',
                                    });
                                }}
                                helperText={mandatoryErrors.name}
                                error={!!mandatoryErrors.name}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Box>
                    { isEditMode ? (
                        <Box className={classes.filledButton}>
                            <Button variant="contained" color="primary" onClick={handleSaveAs}>
                                Save
                            </Button>
                        </Box>
                    ) : null}
                    <Box className={classes.filledButton}>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            { isEditMode ? 'Continue' : 'Create' }
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default SaveNewReportModel;
