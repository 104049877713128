import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_UNIT_COST_ESTIMATES = (
    skip: number,
    take: number,
    orgId: string,
    projectId: string,
    versionId: string,
    exchangeRate?: number,
    isResourced?: boolean,
) => {
    const query = gql`
        query GetUnitCostEstimates {
            unit_cost_estimate(
                skip: ${skip}, 
                take: ${take || 100},
                org_id: ${addEscapeCharacters(orgId)},
                project_id: ${addEscapeCharacters(projectId)}, 
                version_id: ${addEscapeCharacters(versionId)},
                exchangeRate: ${exchangeRate},
                isResourced: ${isResourced || false}
            ) {
                data {
                    estimatedData {
                    id
                    sequence
                    description
                    detailed_description
                    quantity
                    ur_code
                    class
                    unit_cost
                    total_unit_cost
                    total_cost
                    wbs_id
                    coa_id
                    case_id
                    phase_id
                    package_labour_id
                    package_construction_equip_id
                    package_material_id
                    package_permanent_equip_id
                    package_subcontract_id
                    package_indirect_id
                    unit_rate_id
                    item_type
                    detailed_description
                    base_quantity
                    design_growth
                    unit_of_measure
                    wastage_factor
                    prod_factor
                    markup_labour
                    markup_construction_equip
                    markup_material
                    markup_permanent_equip
                    markup_subcontract
                    unit_man_hours
                    unit_labour_cost
                    unit_consequip_cost
                    unit_material_cost
                    unit_pequipment_cost
                    unit_subcontract_cost
                    unit_indirect_cost
                    labour_hours
                    labour_cost
                    construction_equip_cost
                    material_cost
                    permanent_equip_cost
                    subcontract_cost
                    indirect_cost
                    wbs {
                        code
                        description
                        wbs_level
                        code1
                        code2
                        code3
                        code4
                        first_parent
                        second_parent
                        third_parent
                        forth_parent
                        fifth_parent
                        self_code_desc
                    }
                    coa {
                        code
                        coa_level
                        code1
                        code2
                        first_parent
                        second_parent
                        third_parent
                        forth_parent
                        fifth_parent
                        self_code_desc
                        description
                    }
                    case {
                        code
                        name
                    }
                    phase {
                        code
                        name
                    }
                    package_labour {
                        code
                    }
                    package_construction {
                        code
                    }
                    package_material {
                        code
                    }
                    package_permanent {
                        code
                    }
                    package_subcontract {
                        code
                    }
                    package_indirect {
                        code
                    }
                    unit_rate {
                        code
                    }
                    gCoa {
                        description
                    }  
                    unit_cost_data {
                        id
                        entity_id
                        equipment
                        labour
                        labour_hours
                        material
                        p_equipment
                        quantity
                        total_quantity
                        sub_code
                        entity_type
                        entity_cost
                        entity_code
                        entity_description
                        total_unit_resource
                        subcontract
                        entity_unit
                    }
                    }
                }
            }
        }
    `;

    return query;
};

export default {
    GET_UNIT_COST_ESTIMATES,
};
