import React, {
    useState, useEffect, useContext, useRef, MutableRefObject,
} from 'react';
import useStyles from './Reports.style';
import {
    Box, Checkbox, Grid, Typography,
} from '@mui/material';
import AgGridComponent from '../../../../components/agGridTableComponent';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import { UserContext } from '../../../../store/context/userContext';
import ReportsHeader from '../../components/ReportsHeader';
import { Viewer as ReportViewer, ReportInfo, Viewer } from '@grapecity/activereports-react';
import { AgGridReact } from 'ag-grid-react';
import { ReportType } from '../../../../types/Reports';
import reportQuery from '../../../../queries/report';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import { useLocation, useOutletContext } from 'react-router-dom';
import { CellRendererType } from '../../../../types/AgGridTypes';
import { getAccessTokenFromLocalStorage, getBackendEndpoint } from '../../../../helper/StorageHelper';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { Core, PdfExport } from '@grapecity/activereports';
import Loader from '../../../../components/Loader';
import { PATH_CMFR, PATH_DATABASE } from '../../../../Routes/path';
import getQueryDetailsByDataset from '../../../../components/report/helper';
import { ProjectContext } from '../../../../store/context/projectContext';
import getNewSubReportSection from '../../../../helper/GetNewReportSection';
import { Panel, PanelGroup } from 'react-resizable-panels';
import ResizeIcon from '../../components/ResizeIcon';
import { ResourceLocator } from '@grapecity/activereports/core';
import toast from 'react-hot-toast';

const ORGANISATION_MODULES = ['dashboard', 'industries', 'commodities', 'projects'];
const PROJECT_MODULES = ['project dashboard', 'wbs', 'coa', 'packages', 'estimate', 'unit rate', 'resources', 'schedule', 'project settings'];

const isValidModule = (pathname: string, moduleName: string) => {
    if (pathname.includes(PATH_CMFR.capex.reports)) {
        return PROJECT_MODULES.includes(moduleName);
    }

    return ORGANISATION_MODULES.includes(moduleName);
};

const INITIAL_COMBINED_REPORT_STATE: ReportType = {
    id: '',
    display_name: 'report.rdlx-json',
    data: '{"Name": "Report","Body": {"ReportItems": []}}',
    module_name: '',
};

const MODULE_FILTER_ORGANISATIONS = [
    { label: 'All', value: 'all' },
    { label: 'Dashboard', value: 'dashboard' },
    { label: 'Industries', value: 'industries' },
    { label: 'Commodities', value: 'commodities' },
    { label: 'Projects', value: 'projects' },
];

const PROJECT_MODULE_FILTER_ORGANISATIONS = [
    { label: 'All', value: 'all' },
    { label: 'Project Dashboard', value: 'project dashboard' },
    { label: 'WBS', value: 'wbs' },
    { label: 'COA', value: 'coa' },
    { label: 'Packages', value: 'packages' },
    { label: 'Estimate', value: 'estimate' },
    { label: 'Unit Rate', value: 'unit rate' },
    { label: 'Resources', value: 'resources' },
    { label: 'Schedule', value: 'schedule' },
    { label: 'Project Settings', value: 'project settings' },
];

function Reports() {
    const classes = useStyles();
    const userCtx = useContext(UserContext);
    const [selectedReport, setSelectedReport] = useState<ReportType[]>([]);

    // As part of new requirements in Phase2.1 Priority-4 reverted feature of combined-reports.
    // In the new requirements, download multiselected reports seperately.
    // if multiple reports are selected we can store the json in this ref
    const combinedReport = useRef<ReportType>(INITIAL_COMBINED_REPORT_STATE);
    const cont: OutletContext = useOutletContext();
    const { pathname, search } = useLocation();
    const query = new URLSearchParams(search);
    const moduleFilterName = query.get('module');
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [exporting, setExporting] = useState(false);
    const projectCtx = useContext(ProjectContext);
    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate;

    // reverted the code for previewing multiple reports
    // partial resourcelocator object used to get report data of subreports, in preview & download.
    const resourceLocator: Partial<ResourceLocator> = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getResource: (resourceId: string) => {
            const subReportJSON = selectedReport.find((sR) => sR.id === resourceId);
            // To apply theme, we need to fetch the theme url and return as resolved promise
            if (!subReportJSON && resourceId.endsWith('.rdlx-json-theme')) {
                return fetch(resourceId).then((response) => response.json());
            }

            if (!subReportJSON) return null;
            const subReportObject = JSON.parse(subReportJSON?.data || '{}') as ReportInfo['definition'];
            subReportObject.DataSources?.forEach((dataSource) => {
                if (dataSource.ConnectionProperties) {
                    // eslint-disable-next-line no-param-reassign
                    dataSource.ConnectionProperties.ConnectString = `endpoint=${getBackendEndpoint()}`
                    + `;Header$Authorization=Bearer ${getAccessTokenFromLocalStorage() || ''};`;
                }
            });

            // For every dataset, the reports will include the current project and version data.
            subReportObject.DataSets?.forEach((dSet) => {
                const datasetName = dSet.Name;
                if (dSet.Query) {
                    // eslint-disable-next-line no-param-reassign
                    dSet.Query.CommandText = getQueryDetailsByDataset(
                        userCtx?.user?.default_org_id || '',
                        projectCtx?.project?.id || '',
                        projectCtx?.project?.version_id || '',
                        datasetName,
                        exchangeRate as number,
                    );
                }
            });
            return subReportObject;
        },
    };

    let moduleInitialValue = 'all';
    if (moduleFilterName && isValidModule(pathname, moduleFilterName)) {
        // Check if passed module name is valid and filter as per module
        const filterMenuItems = pathname.includes(PATH_DATABASE.reports) ? MODULE_FILTER_ORGANISATIONS : PROJECT_MODULE_FILTER_ORGANISATIONS;
        moduleInitialValue = filterMenuItems.find((item) => item.value === moduleFilterName)?.value || 'all';
    }

    const [selectedModule, setSelectedModule] = useState<string>(moduleInitialValue);

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    let filterModule: string[] = [];
    if (selectedModule && selectedModule !== 'all') {
        filterModule = [selectedModule];
    } else {
        filterModule = pathname.includes(PATH_CMFR.capex.reports) ? PROJECT_MODULES : ORGANISATION_MODULES;
    }

    // Report fetching query
    const { data, isFetching, refetch } = useGQLQuery(
        'reports',
        reportQuery.FETCH_REPORT_DETAILS(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            filterModule,
        ),
        {},
        {
            // onSuccess: handleApiSuccess,
            onError: handleApiError,
        },
    );

    const handleRowSelection = (checked: boolean, row: ReportType) => {
        // commented code for now for previewing multiple reports
        // if (checked) {
        //     setSelectedReport((prevState) => [...prevState, row]);
        // } else {
        //     setSelectedReport((prevState) => prevState.filter((el) => el.id !== row.id));
        // }

        if (selectedReport.length > 1 && selectedReport[0].id === row.id) {
            cont.showNotificationBar('In multiple report selection, cannot unselect parent report.', 'error');
            return;
        }
        setSelectedReport((prevState) => {
            if (checked) {
                const newState = [...prevState];
                newState.push(row);
                if (prevState.length < 1) {
                    combinedReport.current = row;
                } else {
                    const newSection = getNewSubReportSection(newState.length, row.id, row);
                    const combinedReportObj = JSON.parse(combinedReport.current?.data || '{}') as (ReportInfo['definition']);
                    combinedReportObj.ReportSections?.push(newSection[0]);
                    combinedReport.current.data = JSON.stringify(combinedReportObj);
                }
                return newState;
            }
            const newState = prevState.filter((el) => el.id !== row.id);
            if (newState.length) {
                const combinedReportObj = JSON.parse(combinedReport.current?.data || '{}') as (ReportInfo['definition']);
                combinedReportObj.ReportSections = combinedReportObj.ReportSections?.filter((el) => {
                    if (!el.DisplayName || (el.DisplayName !== row.id)) return true;
                    return false;
                });
                combinedReport.current.data = JSON.stringify(combinedReportObj);
            } else {
                combinedReport.current = INITIAL_COMBINED_REPORT_STATE;
            }
            return newState;
        });
    };

    const reportListUpdate = (isEditMode: boolean) => {
        setSelectedReport([]);
        combinedReport.current = INITIAL_COMBINED_REPORT_STATE;
        refetch();
    };

    const reportDeleted = () => {
        setSelectedReport([]);
        combinedReport.current = INITIAL_COMBINED_REPORT_STATE;
    };

    const updateEditedReport = (report: ReportType) => {
        setSelectedReport([report]);
        combinedReport.current = report;
    };

    const columnDefs = [
        {
            field: '',
            headerName: '',
            width: 55,
            // eslint-disable-next-line react/no-unstable-nested-components
            cellRenderer: (params: CellRendererType) => {
                const currentReportId = params.data.id;
                return (
                    <Checkbox
                        style={{
                            margin: '0px', width: '5px', height: '6px', cursor: 'pointer',
                        }}
                        className={classes.gridCheckbox}
                        checked={!!selectedReport.find((el) => el.id === currentReportId)}
                        onChange={(e) => handleRowSelection(e.target.checked, params.data)}
                        sx={{
                            '&.Mui-checked': {
                                color: '#021948',
                            },
                        }}
                        size="small"
                    />
                );
            },
        },
        {
            field: 'display_name',
            flex: 1,
            headerName: 'Report Name',
            sort: 'asc',
            // eslint-disable-next-line react/no-unstable-nested-components
            cellRenderer: (params: CellRendererType) => {
                const index = selectedReport.findIndex((item) => item.id === params.data.id);
                return (
                    <div className={classes.customReportNameBox}>
                        <span className="reportValue">{ params.value}</span>
                        {selectedReport.length > 1 && index !== -1 && <span>{ `(${index + 1})`}</span>}
                    </div>
                );
            },
        },
    ];

    const rowData = data?.reports ? data?.reports.data?.map((item) => item) : [];
    const gridRef = useRef<AgGridReact<ReportType>>(null);
    const viewerRef = useRef() as MutableRefObject<Viewer>;

    useEffect(() => {
        // if (Array.isArray(selectedReport) && selectedReport.length === 1) {
        if (combinedReport.current && combinedReport.current.id) {
            // Connection string of datasource should be dynamic as the access token changes everytime a report is rendered
            // const dynamicReportData = JSON.parse(selectedReport[0].data) as ReportInfo['definition'];
            const reportData = JSON.parse(combinedReport.current.data || '{}') as ReportType;
            const dynamicReportData = JSON.parse(JSON.stringify(reportData)) as ReportInfo['definition'];
            if (dynamicReportData
                && dynamicReportData.DataSources
            ) {
                dynamicReportData.DataSources.forEach((dataSource) => {
                    if (dataSource.ConnectionProperties) {
                        // eslint-disable-next-line no-param-reassign
                        dataSource.ConnectionProperties.ConnectString = `endpoint=${getBackendEndpoint()}`
                        + `;Header$Authorization=Bearer ${getAccessTokenFromLocalStorage() || ''};`;
                    }
                });
            }

            // Dynamic orgID and projectID in datasets
            if (dynamicReportData && dynamicReportData.DataSets) {
                dynamicReportData.DataSets.forEach((dSet) => {
                    const datasetName = dSet.Name;
                    if (dSet.Query) {
                    // eslint-disable-next-line no-param-reassign
                        dSet.Query.CommandText = getQueryDetailsByDataset(
                            userCtx?.user?.default_org_id || '',
                            projectCtx?.project?.id || '',
                            projectCtx?.project?.version_id || '',
                            datasetName,
                            exchangeRate as number,
                        );
                    }
                });
            }
            viewerRef.current?.Viewer.open(dynamicReportData, { ResourceLocator: resourceLocator })
                .then((res) => {})
                .catch((err) => console.log(err));
        }
    }, [selectedReport]);

    useEffect(() => {
        setSelectedReport([]);
        refetch();
    }, [selectedModule]);

    useEffect(() => {
        refetch();
        setSelectedReport([]);
    }, [pageSkipValue]);

    const onReportExport = (saveAll: boolean) => {
        toast.loading('Generating PDF report, this may take a few moments. Please wait...');
        setExporting(true);

        // Taking the count of selected report to identify the first report
        const selectedModuleLength = [...selectedReport].length;

        /**
         * This function takes in an array of selected reports and downloads them one by one.
         * In the same sequence it was selected.
         * Rest logic is from previous buids
         * @param rReport ReportType[]
         * @returns
         */
        const downloadReport = (rReport: ReportType[]) => {
        // Return if the array is empty and setExporting to false
            if (!rReport || rReport.length === 0) {
                setExporting(false);
                return;
            }

            const report = new Core.PageReport();
            const reportEl = rReport.shift();
            const dynamicReportData = JSON.parse(reportEl?.data || '{}') as ReportInfo['definition'];

            if (rReport.length === selectedModuleLength - 1) {
            // Retain only the first ReportSection in dynamicReportData
                if (dynamicReportData.ReportSections && dynamicReportData.ReportSections.length > 1) {
                    dynamicReportData.ReportSections = [dynamicReportData.ReportSections[0]];
                }
            }

            if (dynamicReportData && dynamicReportData.DataSources) {
                dynamicReportData.DataSources.forEach((dataSource) => {
                    if (dataSource.ConnectionProperties) {
                    // eslint-disable-next-line no-param-reassign
                        dataSource.ConnectionProperties.ConnectString = `endpoint=${getBackendEndpoint()}`
                        + `;Header$Authorization=Bearer ${getAccessTokenFromLocalStorage() || ''};`;
                    }
                });
            }

            // Dynamic orgID and projectID in datasets
            if (dynamicReportData && dynamicReportData.DataSets) {
                dynamicReportData.DataSets.forEach((dSet) => {
                    const datasetName = dSet.Name;
                    if (dSet.Query) {
                    // eslint-disable-next-line no-param-reassign
                        dSet.Query.CommandText = getQueryDetailsByDataset(
                            userCtx?.user?.default_org_id || '',
                            projectCtx?.project?.id || '',
                            projectCtx?.project?.version_id || '',
                            datasetName,
                            exchangeRate as number,
                        );
                    }
                });
            }

            // converted previous async await into .then for it to be easily recursive
            report.load(dynamicReportData, { resourceLocator })
                .then(() => report.run())
                .then((document) => PdfExport.exportDocument(document, {
                    info: {
                        title: 'Report',
                    },
                }))
                .then((result) => {
                    result.download(reportEl?.display_name);
                    if (rReport.length) {
                        downloadReport(rReport);
                    } else {
                        setExporting(false);
                        toast.dismiss();
                    }
                })
                .catch((() => {
                    cont.showNotificationBar('Error downloading report!', 'error');
                    toast.dismiss();
                    setExporting(false);
                }));
        };

        downloadReport(saveAll ? [...selectedReport] : [combinedReport.current]);
    };

    return (
        <div>
            <Loader loading={exporting} />
            <ReportsHeader
                reportListUpdate={reportListUpdate}
                updateEditedReport={updateEditedReport}
                selectedReport={selectedReport[0]}
                reportDeleted={reportDeleted}
                selectedModule={selectedModule}
                setSelectedModule={setSelectedModule}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onReportExport={onReportExport}
                isMultipleSelected={selectedReport.length > 1}
            />
            {/* Remove Grid and enable Panel */}
            <PanelGroup autoSaveId="example" direction="horizontal" className={classes.reportBody}>
                <Panel defaultSize={25} minSize={10} maxSize={50}>
                    <Box className={classes.tableSec}>
                        <Loader loading={isFetching} isFromAGGrid />
                        <AgGridComponent
                            columnDefs={columnDefs}
                            rowData={rowData || []}
                            gridRef={gridRef}
                            changeSortingValue={(str: string) => {}}
                            noSelection
                            disableResizable
                            reportsParentRow={selectedReport[0]?.id}
                        />
                    </Box>
                </Panel>
                <ResizeIcon />
                <Panel>
                    <Box className={classes.chartPreviewBox}>
                        <Typography variant="h5" className={classes.preText}>Preview Selected Report</Typography>
                        {Array.isArray(selectedReport) && selectedReport.length ? (
                            <Box className={classes.chartPreviewWrapper}>
                                <ReportViewer ref={viewerRef} />
                            </Box>
                        ) : <Typography className={classes.noDataContainer}>No reports selected</Typography>}
                    </Box>
                </Panel>
            </PanelGroup>
        </div>
    );
}

export default Reports;
