import { makeStyles } from '@mui/styles';

const TableHeaderStyle = makeStyles({
    TableHeader: {
        margin: '0px 0px 22px 0px',
        color: '#021948',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& h6': {
            fontSize: '22px',
            fontWeight: '700',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            '& span': {
                fontSize: '11px',
                fontWeight: '600',
            },
        },
        '& .donutchart-innertext': {
            visibility: 'hidden',
        },
        '& .donutchart-arcs': {
            '& path': {
                stroke: '#ffffff',
                opacity: '1',
            },
        },
    },
    inTableHeader: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            margin: '0px',
        },
        '& h2': {
            fontWeight: '700',
            color: '#1c1c1c !important',
        },
        '& input': {
            color: '#787878',
            fontSize: '12px',
            fontFamily: 'Inter-Medium',
            border: '1px solid #787878',
            padding: '7px 10px 7px 30px',
            width: '143px',
            borderRadius: '4px',
            letterSpacing: '.5px',
            fontWeight: '500',
        },
        '& .MuiFormControl-root': {
            marginLeft: '15px',
        },
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '18px',
            height: '21px !important',
            borderRadius: '5px !important',
            border: '1px solid #787878',
            backgroundColor: '#ffffff !important',
            alignItems: 'center',
            display: 'flex',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            width: '185px',
            borderRadius: '8px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                border: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        },
        '& .MuiInputBase-input': {
            padding: '4px 32px 4px 12px !important',
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.57)',
            fontFamily: 'Inter-Medium',
            display: 'block',
        },
    },
    deleteIcon: {
        minWidth: 'unset !important',
        '& svg': {
            fontSize: '20px',
            color: '#B00000',
        },
    },
    selectText: {
        '& span': {
            width: '185px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    chartStyle: {
        marginLeft: '25px',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
        borderRadius: '9.52px',
        padding: '2.5px 12px',
        '& svg': {
            verticalAlign: 'text-bottom',
        },
        '& span': {
            verticalAlign: 'middle',
            '&:nth-child(1)': {
                color: '#35B83D',
            },
            '&:nth-child(2)': {
                color: '#484DFF',
            },
            '&:nth-child(3)': {
                color: '#353535',
            },
        },
    },
    versonUndo: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControl-root': {
            // marginLeft: '15px',
        },
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '18px',
            height: '21px !important',
            borderRadius: '5px !important',
            border: '1px solid #787878',
            backgroundColor: '#ffffff !important',
            alignItems: 'center',
            display: 'flex',
        },
        '& .MuiInputBase-root': {
            backgroundColor: '#fff !important',
            width: '135px',
            borderRadius: '4px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                backgroundColor: '#fff',
            },
        },
        '& .Mui-focused': {
            backgroundColor: '#fff !important',
        },
        '& .MuiInputBase-input': {
            padding: '4px 32px 4px 12px !important',
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.57)',
            fontFamily: 'Inter-Medium',
        },
        '& svg': {
            color: '#787878',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '4px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
        '& .Mui-disabled': {
            fontSize: '12px',
            color: '#D9D9D9',
            border: '1px solid #D9D9D9',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#D9D9D9',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    inputBoxStyle: {
        position: 'relative',
        marginLeft: '15px',
        '& svg': {
            position: 'absolute',
            top: '50%',
            left: '5px',
            transform: 'translateY(-50%)',
            color: '#787878 !important',
            fontSize: '17px',
        },
    },
    deleteButtonStyle: {
        '& button': {
            color: '#B00000',
            border: '1px solid #B00000',
        },
        '& svg': {
            color: '#B00000 !important',
        },
    },
    selectedDropDown: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    undoButtnNew: {
        marginLeft: '10px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '7px 11px',
            cursor: 'pointer',
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
    clientSideIcon: {
        marginLeft: '3px',
        color: '#0D86FB',
    },
});

export default TableHeaderStyle;
