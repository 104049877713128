import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography,
} from '@mui/material';
import { ColumnState } from 'ag-grid-community';
import React, { useContext, useEffect, useState } from 'react';
import { ProjectContext } from '../../../../store/context/projectContext';
import { UserContext } from '../../../../store/context/userContext';
import SaveGridStyles from './SaveGridLayout.styles';

interface CurrentGridLayout {
    id: string;
    org_id: string;
    user_id: string;
    project_id: string;
    grid_layout_id: string;
    created_at: string;
    updated_at: string;
}

interface EstimateGridLayoutType {
    id?: string;
    pivot_mode?: boolean;
    grid_state?: string;
    layout_name?: string;
    org_id: string;
    created_at: string;
    updated_at: string;
    current_grid_layouts?: CurrentGridLayout[];
}

interface GetEstimateGridLayoutType {
    getEstimateGridLayout?: {
      data?: EstimateGridLayoutType[];
    }
}

interface MutateType {
    pivot_mode?: boolean,
    layout_name: string,
    org_id: string,
    project_id: string,
    module_name?: string,
    grid_state?: string,
}

interface StateType {
    state?: ColumnState[];
    filter?: Record<string, any>;
}

interface Props {
    open: boolean,
    gridView?: string,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    gridState?: StateType,
    handleOpen?: (p: boolean) => void,
    mutate: (params: MutateType) => void;
    setLayoutName?:(p: string) => void
    layoutName?: string,
    gridLayoutData?: GetEstimateGridLayoutType,
    setGridView?: React.Dispatch<React.SetStateAction<any>>;
    pivotMode?: boolean;
    setCanRenderClientSide?: React.Dispatch<React.SetStateAction<boolean>>;
}

function RestoreVersionModal(props: Props) {
    const classes = SaveGridStyles();
    const {
        open, setOpen, gridState, gridView, handleOpen, mutate, setLayoutName, setGridView,
        layoutName, gridLayoutData, pivotMode, setCanRenderClientSide,
    } = props;
    const [saveView, setSaveView] = useState(true);
    const projectCtx = useContext(ProjectContext);
    const userCtx = useContext(UserContext);

    useEffect(() => {
        if (gridView) {
            setLayoutName?.(gridView);
        }
    }, [gridView]);

    const saveLayout = () => {
        const currentLayout = gridLayoutData?.getEstimateGridLayout?.data?.find((layout) => layout.current_grid_layouts?.some(
            (grid) => grid.user_id === userCtx?.user?.user_id
                && grid.project_id === projectCtx?.project?.id
                && grid.org_id === userCtx?.user?.default_org_id,
        ));
        mutate({
            pivot_mode: pivotMode as boolean,
            grid_state: JSON.stringify(gridState),
            project_id: projectCtx?.project?.id || '',
            org_id: userCtx?.user?.default_org_id || '',
            layout_name: layoutName?.trim() || '',
            module_name: 'estimate',
        });

        setSaveView(true);
        setGridView?.(currentLayout?.layout_name);
    };

    const handleSave = () => {
        const filteredLayouts = gridLayoutData && gridLayoutData?.getEstimateGridLayout?.data && gridLayoutData?.getEstimateGridLayout?.data.filter(
            (item: EstimateGridLayoutType) => item?.layout_name === layoutName?.trim(),
        );
        if (gridView === layoutName || (filteredLayouts && filteredLayouts.length > 0)) {
            if (gridView === layoutName && gridView) {
                setLayoutName?.(gridView);
            }
            setSaveView(false);
        } else {
            saveLayout();
            setCanRenderClientSide?.(() => false);
            setOpen?.(false);
            // setLayoutName?.('');
        }
    };

    // Handler for text field value change
    const handleLayoutNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLayoutName?.(event.target.value);
    };

    const handleOverwrite = () => {
        saveLayout();
        setCanRenderClientSide?.(() => false);
        setOpen?.(false);
    };
    useEffect(() => {
        setGridView?.('');
    }, [userCtx?.user?.default_org_id]);

    useEffect(() => {
        if (!layoutName || layoutName === 'Default View') {
            setSaveView(true);
        }
    }, [layoutName]);

    const selectedOrgName = (userCtx?.user?.orgnizations?.length)
        && userCtx.user.orgnizations.find((org) => org.id === userCtx?.user?.default_org_id);

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxSection}>Save Grid Layout</DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Grid container spacing={2} className={classes.sectionBody}>
                        <Grid item xs={12}>
                            {saveView ? (
                                <>
                                    <Typography variant="subtitle2" className={classes.labelText}>
                                        Name
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        color="secondary"
                                        placeholder="Layout"
                                        value={(layoutName === 'Default View' ? '' : layoutName)}
                                        onChange={handleLayoutNameChange}
                                    />
                                    <Typography className={classes.text}>
                                        {selectedOrgName
                             && typeof selectedOrgName !== 'number'
                                            ? `Grid layouts are shared with all the members of ${selectedOrgName.name} organization.`
                                            : 'the organization.'}
                                    </Typography>
                                </>
                            ) : (
                                <Typography variant="h3" className={classes.warning}>
                                    <span className={classes.textHead}>Warning : </span>
                                    A grid layout with the name
                                    {' '}
                                    {layoutName}
                                    {' '}
                                    already exists. Do you want to overwrite?
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    {saveView ? (
                        <>
                            <Box className={classes.outlineButton}>
                                <Button variant="outlined" onClick={() => handleOpen?.(false)}>Cancel</Button>
                            </Box>
                            <Box className={classes.filledButton}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!layoutName?.trim().length
                                    || (layoutName === 'Default View')}
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box className={classes.outlineButton}>
                                <Button variant="outlined" onClick={() => setSaveView(true)}>Rename</Button>
                            </Box>
                            <Box className={classes.deleteButton}>
                                <Button variant="outlined" onClick={handleOverwrite}>Overwrite</Button>
                            </Box>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default RestoreVersionModal;
