import React, {
    useState, useContext, useMemo, useCallback, useRef, useEffect,
} from 'react';
import useStyles from './UnitRateListView.style';
import { Box } from '@mui/material';
import { CellRendererDetailsType, CellRendererType } from '../../../../types/AgGridTypes';
import AgGridComponent from '../../../../components/agGridTableComponent';
import { fetchData } from '../../../../hooks/useGQLQuery';
import unitRateQueries from '../../../../queries/unitrates';
import Loader from '../../../../components/Loader';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import {
    useLocation, Link, useNavigate, useOutletContext,
} from 'react-router-dom';
import { useDisplayCurrFormatter, formatCurr } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';
import {
    IServerSideDatasource, IServerSideGetRowsParams, ICellRendererParams, RowHeightParams,
    SetFilterValuesFuncParams, IMultiFilter, FilterChangedEvent,
    ColSpanParams,
} from 'ag-grid-community';
import { PATH_CMFR } from '../../../../Routes/path';
import { useDebounce } from '../../../../hooks/useDebounce';
import { OutletContext } from '../../../../types/CommoditiesListType';
import gqlConfig from '../../../../helper/gqlConfig';
import UnitRateType from '../../../../types/UnitRateType';
import formatRowData from '../../../../helper/rowFormat';
import UnitCostType from '../../../../types/UnitCostType';
import { AgGridReact } from 'ag-grid-react';
import { SetFilter } from 'ag-grid-enterprise';

interface LocationState {
    unitRateId?: string;
}

interface Props {
    handleReset?: () => void;
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>,
    isFetching: boolean,
    selectedCoaLabel: string | undefined,
    isGlobalSearchActive: boolean,
    hideUnusedFlag: boolean,
    setSearchText: React.Dispatch<React.SetStateAction<string>>,
    searchText: string,
    selectedCoaCodeOne: string,
    unitRateId: string | undefined,
    handleClear:()=>void,
    flagForCoa?: boolean,
    nestedFlag?: boolean,
    setNestedFlag?: React.Dispatch<React.SetStateAction<boolean>>,
    setUnitRateId?: React.Dispatch<React.SetStateAction<string | undefined>>,
    setNestedUnitCode?: React.Dispatch<React.SetStateAction<string | undefined>>,
    nestedUnitCodeData?: string,
}

interface UnitCostData {
    unit_cost_data:UnitCostType[];
}

interface UnitCostResponse {
    unitCostByUnitRateId: UnitCostData;
}

interface UnitRateResponse {
    unitrateListView?:
        {
            data: UnitRateType[];
        }
}

interface FilterModelType {
    unit?: {
        filterModels: any[]
    },
}

interface FilterModel {
    coa_code?: FilterModelText;
    code?: FilterModelText;
    description?: FilterModelText;
    unit?: FilterModelDetails;
    unit_man_hours?: FilterModelText;
    lab_rate?: FilterModelText;
    unit_labour_cost?: FilterModelText;
    unit_material_cost?: FilterModelText;
    unit_consequip_cost?: FilterModelText;
    unit_cost?: FilterModelText;
    quantity_in_estimate?: FilterModelText;
    cost_in_estimate?: FilterModelText;
    total_unit_cost?: FilterModelText;
}

interface FilterModelDetails {
  filterType: 'multi';
  filterModels: [TextFilterModel | null, SetFilterModel | null];
}

interface FilterModelText {
  filterType: 'text';
  type: 'contains';
  filter: string;
}

interface TextFilterModel {
  filterType: 'text';
  operator: 'AND';
  condition1: Condition;
  condition2: Condition;
  conditions: Condition[];
}

interface SetFilterModel {
  values: string[];
  filterType: 'set';
}

interface Condition {
  filterType: 'text';
  type: 'contains';
  filter: string;
}

function UnitRateListView(props:Props) {
    const {
        searchText, selectedCoaCodeOne, setSearchText, hideUnusedFlag, isGlobalSearchActive, handleReset, selectedCoaLabel, setIsFetching, isFetching,
        unitRateId, handleClear,
        flagForCoa, nestedFlag, setNestedFlag, setUnitRateId, setNestedUnitCode, nestedUnitCodeData,
    } = props;
    const classes = useStyles();
    const [resourceSortingState, setResourceSortingState] = useState('');
    const [nestedUnitRateId, setNestedUnitRateId] = useState<string>();
    const [sortOrder, setSortOrder] = useState('');
    const [filterFlag, setFilterFlag] = useState<UnitRateType[]>();
    const userContext = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const formatCurrency = useDisplayCurrFormatter();
    const navigate = useNavigate();
    const debouncingValue = useDebounce(searchText);
    const graphQLClient = gqlConfig('/list');
    const [nestedUnitData, setNestedUnitdDescr] = useState<string>();
    const [estimateFlag, setEstimateFlag] = useState<string>();
    const handleLink = (p: UnitRateType) => {
        if (p.entity_type === 'U' && p?.coa_code) {
            setNestedUnitRateId(p?.entity_id);
            setEstimateFlag(p.entity_id);
            setNestedFlag?.(true);
            setNestedUnitCode?.(
                p?.coa_code
                    ? `${p.coa_code[0] + '0'.repeat(p.coa_code.length - 1)}`
                    : '',
            );
            setNestedUnitdDescr(p?.entity_description);
            if (unitRateId) {
                setUnitRateId?.('');
            }
        } else {
            navigate(PATH_CMFR.capex.resources, {
                state: {
                    unitCostId: p?.id, code: p?.entity_code, description: p?.entity_description, resourceType: p?.resource_type,
                },
            });
        }
    };

    const cont: OutletContext = useOutletContext();
    const masterDetailsCache = useRef<{ [key: string]: UnitCostType[] }>({});
    const ref = useRef<AgGridReact>(null);
    const fetchRef = useRef<boolean>(true);
    const firstCallRef = useRef<boolean>(false);
    const filterRef = useRef<FilterModel>({});
    const searchRef = useRef<string | null>(null);
    const setValueRef = useRef<(string | null)[]>([]);
    const currentCoaCode = selectedCoaCodeOne || 'default';
    const rowDataCacheRef = useRef<{
        [key: string]: {
            rowData: UnitRateType[],
            exchangeRate: number
        }
    }>({});

    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate;
    const colFormat = ['lab_rate', 'unit_labour_cost', 'unit_material_cost', 'unit_consequip_cost',
        'unit_cost', 'cost_in_estimate', 'total_unit_cost', 'unit_cost_data'];
    const masterDataColFormat = ['entity_cost', 'labour', 'material', 'equipment', 'p_equipment', 'subcontract', 'total_unit_resource'];
    const loadingRenderer = true;
    const isSearchTextEmpty = searchText.length === 0;
    const [isPivotActive, setIsPivotActive] = useState<boolean>(false);
    const [valueColsLength, setValueColsLength] = useState<number>(0);
    const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];
    const changeSortingValue = (par: string) => {
        if (resourceSortingState === par || resourceSortingState === '') {
            if (sortOrder === 'DESC') {
                setSortOrder('ASC');
            } else {
                setSortOrder('DESC');
            }
        }
        setResourceSortingState(par);
    };

    const gridCustomCellRenderer = (params: CellRendererType) => {
        const colName = params.colDef.field;
        const { value, data } = params;
        if (params.value != null) {
            switch (colName) {
            case 'quantity_in_estimate':
            case 'quantity':
            case 'total_quantity':
            case 'labour_hours':
                return (params?.value || params?.value === 0) ? formatCurr(params.value.toFixed(2), local) : '-';
            case 'quantity_child_rates':
                return (params?.value || params?.value === 0) ? formatCurr(params.value.toFixed(0), local) : '-';
            case 'productivity':
                return (params?.value || params?.value === 0) ? formatCurr(params.value.toFixed(3), local, 3) : '-';
            case 'unit_man_hours':
                return (params?.value || params?.value === 0) ? formatCurr(params.value.toFixed(2), local) : '-';
            case 'lab_rate':
            case 'labour':
            case 'material':
            case 'equipment':
            case 'p_equipment':
            case 'subcontract':
            case 'unit_labour_cost':
            case 'unit_material_cost':
            case 'unit_consequip_cost':
            case 'total_unit_cost':
            case 'unit_indirect_cost':
            case 'unit_subcontract_cost':
            case 'unit_equipment_cost':
                return (params?.value || params?.value === 0) ? formatCurrency(params.value.toFixed(2)) : '-';
            case 'description':
                return (
                    <span>
                        {value}
                    </span>
                );
            case 'entity_description':
                return (
                    <span>
                        {value}
                    </span>
                );
            case 'entity_code':
                return (
                    <Link
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            handleLink(data);
                        }}
                    >
                        {value}
                    </Link>
                );
            case 'coa_code':
                if (params.data.shouldStyleApply) {
                    return (
                        <span className={classes.boldText}>
                            {params?.value}
                        </span>
                    );
                }
                return params.value;
            default:
                return colName ? formatCurrency(params?.value) : params?.value;
            }
        }
        if (params.value === null) {
            return '-';
        }
        return params?.value;
    };

    const getSetFilterValues = (params: SetFilterValuesFuncParams) => {
        if (!fetchRef.current) {
            params.success(setValueRef.current);
        }
    };

    useEffect(() => {
        if (!isPivotActive && valueColsLength) ref?.current?.columnApi?.setValueColumns([]);
        else if (isPivotActive && !ref?.current?.columnApi?.getRowGroupColumns().length) {
            ref?.current?.columnApi?.resetColumnState();
        }
    }, [isPivotActive]);

    const columnDefs = [
        {
            field: 'row_expand ',
            minWidth: 40,
            maxWidth: 40,
            headerName: '',
            cellRendererSelector: (params: ICellRendererParams) => {
                if (params.node.rowIndex === 0 && !unitRateId && !isGlobalSearchActive) {
                    return { component: null };
                }
                return { component: 'agGroupCellRenderer' };
            },
            sortable: false,
            enableValue: false,
            enableRowGroup: false,
        },
        {
            field: 'coa_code',
            headerName: 'COA',
            initialWidth: 150,
            type: 'string',
            initialSort: 'asc',
            enableValue: false,
            cellRendererFramework: isPivotActive ? null : gridCustomCellRenderer,
            colSpan: (params: ColSpanParams) => {
                if ((params?.data as UnitRateType)?.shouldStyleApply) {
                    return 3;
                }
                return 1;
            },
            flex: 0.8,
        },
        {
            field: 'code',
            headerName: 'Code',
            initialWidth: 150,
            type: 'string',
            initialSort: 'asc',
            enableValue: false,
            flex: 1,
        },
        {
            field: 'description',
            headerName: 'Description',
            initialWidth: 450,
            type: 'string',
            cellRendererFramework: isPivotActive ? null : gridCustomCellRenderer,
            enableValue: false,
            flex: 3.5,
        },
        {
            field: 'unit',
            headerName: 'Unit',
            initialWidth: 120,
            type: 'string',
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: getSetFilterValues,
                            refreshValuesOnOpen: true,
                        },
                    },
                ],
            },
            suppressMenu: !firstCallRef.current,
            enableValue: false,
            flex: 0.6,
        },
        {
            field: 'productivity',
            headerName: 'Productivity',
            initialWidth: 120,
            type: 'numericColumn',
            cellRenderer: gridCustomCellRenderer,
            flex: 0.8,
            initialHide: true,
        },
        {
            field: 'unit_man_hours',
            headerName: 'Labour Hours',
            initialWidth: 160,
            type: 'numericColumn',
            cellRenderer: gridCustomCellRenderer,
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            flex: 1,
        },
        {
            field: 'lab_rate',
            headerName: 'Labour Rate',
            initialWidth: 160,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialHide: true,
            flex: 1,
        },
        {
            field: 'unit_labour_cost',
            headerName: 'Labour',
            initialWidth: 190,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialHide: true,
            flex: 1,
        },
        {
            field: 'unit_material_cost',
            headerName: 'Material',
            initialWidth: 150,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialHide: true,
            flex: 1,
        },
        {
            field: 'unit_consequip_cost',
            headerName: 'C.Equip',
            initialWidth: 150,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialHide: true,
            flex: 1,
        },
        {
            field: 'unit_equipment_cost',
            headerName: 'P.Equip',
            initialWidth: 150,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            flex: 0.8,
            initialHide: true,
        },
        {
            field: 'unit_subcontract_cost',
            headerName: 'Sub',
            initialWidth: 150,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            flex: 1,
            initialHide: true,
        },
        {
            field: 'unit_indirect_cost',
            headerName: 'Indirect',
            initialWidth: 150,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            flex: 1,
            initialHide: true,
        },
        {
            field: 'unit_cost',
            headerName: 'Unit Rate',
            initialWidth: 190,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            flex: 1,
        },
        {
            field: 'quantity_in_estimate',
            headerName: 'Qty',
            initialWidth: 190,
            type: 'numericColumn',
            cellRenderer: gridCustomCellRenderer,
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            flex: 1,
        },
        {
            field: 'cost_in_estimate',
            headerName: 'Total Cost',
            initialWidth: 190,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            flex: 1,
        },
        {
            field: 'total_unit_cost',
            headerName: 'Total Unit Cost',
            initialWidth: 190,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            flex: 1,
        },
        {
            field: 'quantity_child_rates',
            headerName: 'Sub-Cost Qty',
            initialWidth: 150,
            cellRenderer: gridCustomCellRenderer,
            type: 'numericColumn',
            flex: 0.7,
            initialHide: true,
        },
    ];

    // This will clear the selection of row.
    useEffect(() => {
        ref?.current?.api?.deselectAll();
    }, [exchangeRate, debouncingValue, isGlobalSearchActive, selectedCoaCodeOne]);

    const detailCellRendererParams = useMemo(
        () => ({
            detailGridOptions: {
                columnDefs: [
                    {
                        field: 'entity_code',
                        headerName: 'Code',
                        initialWidth: 100,
                        flex: 1.2,
                        cellRendererFramework: gridCustomCellRenderer,
                        lockVisible: true,
                    },
                    {
                        field: 'entity_description',
                        headerName: 'Description',
                        minWidth: 50,
                        cellRendererFramework: gridCustomCellRenderer,
                        initialWidth: 210,
                        flex: 3,
                    },
                    {
                        field: 'quantity',
                        headerName: 'Qty',
                        initialWidth: 90,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 0.8,
                    },
                    {
                        field: 'total_quantity',
                        headerName: 'T.Qty',
                        initialWidth: 85,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 0.8,
                    },
                    {
                        field: 'entity_unit',
                        headerName: 'Unit',
                        initialWidth: 70,
                        flex: 0.9,
                    },
                    {
                        field: 'entity_cost',
                        headerName: 'Cost Per Unit',
                        initialWidth: 120,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1.35,
                    },
                    {
                        field: 'labour_hours',
                        headerName: 'Hours',
                        initialWidth: 95,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                    },
                    {
                        field: 'labour',
                        headerName: 'Labour',
                        initialWidth: 105,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                    },
                    {
                        field: 'material',
                        headerName: 'Material',
                        initialWidth: 110,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                    },
                    {
                        field: 'equipment',
                        headerName: 'C.Equip',
                        initialWidth: 110,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                    },
                    {
                        field: 'p_equipment',
                        headerName: 'P.Equip',
                        initialWidth: 110,
                        type: 'numericColumn',
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                    },
                    {
                        field: 'subcontract',
                        headerName: 'Sub',
                        initialWidth: 110,
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                        type: 'numericColumn',
                    },
                    {
                        field: 'total_unit_resource',
                        headerName: 'Total',
                        initialWidth: 110,
                        cellRendererFramework: gridCustomCellRenderer,
                        flex: 1,
                        type: 'numericColumn',
                    },
                ],
                defaultColDef: {
                    // flex: 1,
                    sortable: true,
                    unSortIcon: true,
                    suppressMenu: false,
                    resizable: true,
                    suppressMovable: true,
                },
                getRowHeight: (params: RowHeightParams) => 27,
                suppressLoadingOverlay: true,
                loadingOverlayComponent: null,
                getContextMenuItems: () => ['copy', 'copyWithHeaders', 'export'],
                enableRangeSelection: true,
                enableRangeHandle: true,
                statusBar: {
                    statusPanels: [
                        {
                            statusPanel: 'agAggregationComponent',
                            align: 'right',
                        },
                    ],
                },
            },

            getDetailRowData(params: CellRendererDetailsType) {
                const unitRateIdParam = params.data.id;
                if (masterDetailsCache.current[unitRateIdParam]) {
                    const cachedData = { unit_cost_data: masterDetailsCache.current[unitRateIdParam] as UnitCostType };
                    const updatedRowData = formatRowData(
                        [cachedData as UnitRateType],
                        colFormat,
                        exchangeRate,
                        undefined,
                        true,
                        masterDataColFormat,
                    );
                    params.successCallback((updatedRowData[0] as UnitCostData)?.unit_cost_data);
                    return;
                }

                const query = unitRateQueries.GET_UNIT_COST_BY_UNIT_RATE_ID(
                    0,
                    1000,
                    userContext?.user?.default_org_id || '',
                    projectCtx?.project?.id || '',
                    projectCtx?.project?.version_id || '',
                    unitRateIdParam,
                );
                setIsFetching(true);
                fetchData(graphQLClient, query, {})
                    .then((response: unknown) => {
                        const data = (response as UnitCostResponse).unitCostByUnitRateId;

                        const updatedRowData = formatRowData(
                            [data as UnitRateType],
                            colFormat,
                            exchangeRate,
                            undefined,
                            true,
                            masterDataColFormat,
                        );

                        const updatedRow = (updatedRowData[0] as UnitCostData).unit_cost_data;
                        masterDetailsCache.current[unitRateIdParam] = updatedRow;

                        params.successCallback(updatedRow);
                        setIsFetching(false);
                    })
                    .catch((err: Error) => {
                        setIsFetching(false);
                        cont.showNotificationBar(err.message.substring(0, 60), 'error');
                    });
            },
        }),
        [projectCtx?.project?.currency, exchangeRate],
    );

    const loadServerSideData = useCallback((params: IServerSideGetRowsParams) => {
        const {
            sortModel, groupKeys, rowGroupCols, pivotMode, valueCols,
        } = params.request;
        setIsPivotActive(() => pivotMode);
        if ((pivotMode && valueCols.length) || (!valueCols.length && !pivotMode)
            || (pivotMode && rowGroupCols.length)) {
            setValueColsLength(() => valueCols.length);
            const startRow = params.request?.startRow;
            const filterModel = params.request.filterModel as FilterModel;
            const newExchangeRate = displayCurr?.exchange_rate;
            const rowDataCache = rowDataCacheRef.current[currentCoaCode];
            const unitFilterModel = filterModel?.unit;
            const isSetFilter = unitFilterModel?.filterModels[1]?.filterType === 'set';
            const emptyOrHasSameSearchText = isSearchTextEmpty || debouncingValue === searchRef.current;
            const hasSameFilter = JSON.stringify(unitFilterModel?.filterModels[0]) === JSON.stringify(filterRef.current?.unit?.filterModels[0]);
            const emptyOrHasSameFilter = !unitFilterModel?.filterModels[0] || hasSameFilter;

            if (filterModel) {
                ref?.current?.api?.deselectAll();
            }

            if (unitFilterModel && isSetFilter && emptyOrHasSameSearchText && emptyOrHasSameFilter) {
                setIsFetching(true);
                let newRow: UnitRateType = {};
                newRow = {
                    coa_code: selectedCoaLabel,
                    shouldStyleApply: true,
                };
                const setFilterValues = unitFilterModel?.filterModels[1]?.values as string[];
                const cachedRowData = rowDataCache.rowData;
                const setFilteredRowData = cachedRowData.filter((unitrate: UnitRateType) => setFilterValues.includes(unitrate.unit as string));
                setFilteredRowData.unshift(newRow);
                const data: UnitRateType[] = Object.keys(filterModel).length ? setFilteredRowData : rowDataCache.rowData;
                const updatedRowData = formatRowData(data, colFormat, newExchangeRate);
                // setValueRef.current = (updatedRowData as UnitRateType[]).map((row) => row.unit as string);
                params.successCallback(updatedRowData as string[], data.length);
                setIsFetching(() => false);
            } else if (rowDataCache?.exchangeRate && newExchangeRate && rowDataCache.exchangeRate !== newExchangeRate) {
                setIsFetching(true);
                const updatedRowData = formatRowData(rowDataCache.rowData, colFormat, newExchangeRate);
                rowDataCache.exchangeRate = newExchangeRate;
                params.successCallback(updatedRowData as string[], rowDataCache.rowData.length);
                setIsFetching(false);
            } else if (exchangeRate) {
                if (
                    ((debouncingValue && !selectedCoaCodeOne)
                        || unitRateId
                        || (!!selectedCoaCodeOne && !debouncingValue && !flagForCoa && isSearchTextEmpty))
                ) {
                    setIsFetching(true);
                    if (!firstCallRef.current) firstCallRef.current = true;
                    fetchRef.current = true;
                    searchRef.current = debouncingValue;
                    filterRef.current = filterModel;

                    const query = unitRateQueries.GET_UNIT_RATE_LIST_VIEW_DATA(
                            startRow as number,
                            DEFAULT_PAGE_SIZE,
                            userContext?.user?.default_org_id || '',
                            projectCtx?.project?.id || '',
                            projectCtx?.project?.version_id || '',
                            unitRateId || '',
                            isGlobalSearchActive ? false : hideUnusedFlag,
                            debouncingValue,
                            newExchangeRate,
                            JSON.stringify(filterModel) || '',
                            sortModel || [],
                            groupKeys || [],
                            JSON.stringify(rowGroupCols) || '',
                            isGlobalSearchActive || unitRateId ? '' : selectedCoaCodeOne,
                            JSON.stringify(valueCols) || '',
                    );

                    fetchData(graphQLClient, query, {}).then((response: unknown) => {
                        const responseWithType = response as UnitRateResponse;
                        const unitRateResponse = responseWithType.unitrateListView;
                        const rowData = unitRateResponse?.data || [];

                        setValueRef.current = rowData.map((row) => row.unit as string);
                        (ref?.current?.api?.getFilterInstance<IMultiFilter>('unit')?.getChildFilterInstance(1) as SetFilter).refreshFilterValues();

                        if (!unitRateId && !isGlobalSearchActive
                            && !groupKeys.length && !rowGroupCols.length && !valueCols.length && selectedCoaCodeOne && !pivotMode
                        ) {
                            let newRow: UnitRateType = {};
                            if (rowGroupCols.length) {
                                const groupingField = rowGroupCols[groupKeys.length].field;
                                newRow = {
                                    [groupingField as string]: selectedCoaLabel,
                                };
                            } else {
                                newRow = {
                                    coa_code: selectedCoaLabel,
                                    shouldStyleApply: true,
                                };
                            }
                            rowData.unshift(newRow);
                        }

                        const lastRow = startRow as number + (rowData?.length || 0);

                        if (rowData.length) {
                            setFilterFlag(rowData);
                        }

                        rowDataCacheRef.current[currentCoaCode] = {
                            rowData,
                            exchangeRate: newExchangeRate as number,
                        };

                        const updatedRowData = formatRowData(rowData, colFormat, newExchangeRate);
                        params.successCallback(updatedRowData as string[], lastRow);
                        if (!updatedRowData.length) {
                            ref?.current?.api?.showNoRowsOverlay();
                        }
                        setIsFetching(false);
                        fetchRef.current = false;
                    }).catch((err: Error) => {
                        cont.showNotificationBar(err.message.substring(0, 60), 'error');
                        setIsFetching(false);
                        fetchRef.current = false;
                        params.failCallback();
                    });
                } else {
                    setIsFetching(false);
                    fetchRef.current = false;
                    params.failCallback();
                }
            }
        } else if (pivotMode || valueCols.length) {
            params.successCallback([], 0);
            ref?.current?.api?.showNoRowsOverlay();
        } else {
            setIsFetching(false);
            fetchRef.current = false;
            params.failCallback();
        }
    }, [selectedCoaCodeOne, exchangeRate, debouncingValue, hideUnusedFlag, unitRateId, flagForCoa, nestedUnitCodeData]);

    const onFilterChanges = (event: FilterChangedEvent) => {
        fetchRef.current = true;
        setIsFetching(true);
        // This refreshes unit set filter values when filter changes in columns other than unit column
        if (ref?.current?.api?.getFilterInstance<IMultiFilter>('unit') && !(event.api.getFilterModel() as FilterModelType).unit?.filterModels[1]) {
            (ref?.current?.api?.getFilterInstance<IMultiFilter>('unit')?.getChildFilterInstance(1) as SetFilter).refreshFilterValues();
        }
    };

    useEffect(() => {
        // To display loading status in setValue, fetchRef is used to hold the status of isFetching
        fetchRef.current = isFetching;
    }, [isFetching]);

    const serverSideDatasource: IServerSideDatasource = useMemo(() => ({
        getRows: (params: IServerSideGetRowsParams) => loadServerSideData(params),
    }), [loadServerSideData]);

    const rowIndex = filterFlag && filterFlag.findIndex((item) => item.id === nestedUnitRateId || item.id === estimateFlag);
    useEffect(() => {
        if (ref.current && rowIndex && filterFlag) {
            setTimeout(() => {
                ref?.current?.api.ensureIndexVisible(rowIndex, 'top');
                const rowNode = ref?.current?.api?.getDisplayedRowAtIndex(rowIndex);
                if (rowNode) {
                    ref?.current?.api?.getSelectedNodes().forEach((node) => {
                        node.setSelected(false);
                    });
                    rowNode.setSelected(true);
                }
            }, 100);

            setNestedUnitRateId('');
        } else {
            ref?.current?.api?.forEachNode((node) => node.setSelected(false));
        }
    }, [nestedUnitRateId, estimateFlag, filterFlag, rowIndex]);

    return (
        <div>
            <Box className={`${unitRateId ? classes.gridHeight1 : classes.gridHeight2} ${classes.projectListMain}`}>
                {unitRateId && filterFlag && (
                    <Box className={classes.filterDesc}>
                        <span>Data filtered by:</span>
                        <span>
                            {' '}
                            {filterFlag[0]?.code}
                            {' '}
                            -
                            {' '}
                            {filterFlag[0]?.description}
                        </span>
                        <button type="button" onClick={() => handleClear()}>Clear</button>
                    </Box>
                )}
                <AgGridComponent
                    gridRef={ref}
                    isAssociation={!!unitRateId}
                    unCheckFirstRow={!unitRateId && !isGlobalSearchActive}
                    columnDefs={columnDefs}
                    datasource={serverSideDatasource}
                    setSearchText={setSearchText}
                    changeSortingValue={changeSortingValue}
                    sortOrder={sortOrder}
                    isRangeSelectable
                    isPinnable
                    masterDetail
                    detailCellRendererParams={detailCellRendererParams}
                    isGroupable
                    isExportEnabled
                    isClipboardEnabled
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    defaultExpanded={1}
                    moduleName="UnitRateListView"
                    colFormat={colFormat}
                    masterDataColFormat={masterDataColFormat}
                    disableResizable
                    loadingRenderer={loadingRenderer}
                    handleReset={handleReset}
                    unitRateRowBGStyle
                    isMultiFilterRequired={false}
                    onFilterChanges={onFilterChanges}
                />
                <Loader loading={isFetching} />
            </Box>
        </div>
    );
}

export default UnitRateListView;
