/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useQuery } from 'react-query';
import gqlConfig from '../helper/gqlConfig';
import Resource from '../types/ResourceType';
import UnitRateType from '../types/UnitRateType';
import WBSDataType from '../types/WbsType';
import COADataType from '../types/CoaType';
import PageInfoType from '../types/PageInfoType';
import TotalValueType from '../types/TotalValueType';
import ProjectListType from '../types/ProjectListType';
import ScheduleType from '../types/ScheduleList';
import IndustriesType from '../types/IndustriesType';
import UserDetailsType from '../types/UserDetailsType';
import UserProjectsListType from '../types/UserProjectsListType';
import OrganisationDetailType from '../types/OrganisationDetailType';
import OrgUserProjects from '../types/OrgUserProjects';
import { OrgUserType } from '../types';
import GetOrgUser from '../types/GetOrgUser';
import GetOrgProjectType from '../types/GetOrgProjectType';
import { getAccessTokenFromLocalStorage } from '../helper/StorageHelper';
import InvitationType from '../types/InvitationTypes';
import ProjectLocationType from '../types/ProjectLoationType';
import ProjectCurrencyType from '../types/ProjectCurrencyType';
import ProjectPhase from '../types/PhaseType';
import CaseType from '../types/CaseType';
import VersionType from '../types/VersionType';
import GetCurrencyType from '../types/GetCurrencyType';
import OrgCostType from '../types/OrgCostType';
import CommoditiesListType from '../types/CommoditiesListType';
import RegionType from '../types/RegionType';
import LabourAndCrewType from '../types/LabourAndCrewType';
import { Totals, PackageType } from '../types/PackageType';
import GetAllIndustry from '../types/GetAllIndurtiesType';
import { useLogout } from './useLogout';
import { EstimateType, EstimateTotalType } from '../types/EstimateType';
import { ReportType } from '../types/Reports';
import { ImportLogType } from '../types/ImportLog';
import GridLayoutType from '../types/GridLayout';
import { GraphQLClient } from 'graphql-request';

const ORG_DISABLED_ERROR_MESSAGE = 'Unexpected error value: "UNAUTHORIZED"';

interface CurrentGridLayout {
    id: string;
    org_id: string;
    user_id: string;
    project_id: string;
    grid_layout_id: string;
    created_at: string;
    updated_at: string;
}

interface EstimateGridLayoutType {
    id?: string;
    pivot_mode?: boolean;
    grid_state?: string;
    layout_name?: string;
    org_id: string;
    created_at: string;
    updated_at: string;
    current_grid_layouts?: CurrentGridLayout[];
}

interface GetEstimateGridLayoutType {
    data: EstimateGridLayoutType[];
}

export const fetchData = async (graphQLClient: GraphQLClient, query: string, variables: any) => graphQLClient.request(query, variables);
interface QueryReturnType {
  data:
  | {
    getEstimateGridLayout?: GetEstimateGridLayoutType,
    getCurrency?: GetCurrencyType[];
    project_setting?: ProjectListType;
    location?: ProjectLocationType;
    resource?: {
      data: Resource[];
      pageInfo: PageInfoType;
    };
    regions?: RegionType[];
    orgCosts?: OrgCostType;
    projectCost?: OrgCostType;
    version?: VersionType[];
    phase?: ProjectPhase[];
    case?: CaseType[];
    getprojectCurrency?: ProjectCurrencyType[];
    currency?: ProjectCurrencyType[] | undefined;
    all_industries?: {
      data?: GetAllIndustry[] | undefined;
    };
    getGridLayout?: GridLayoutType;
    getImportLogList?: {
      data?: ImportLogType[];
      pageInfo: PageInfoType;
    };
    package?: {
      data?: {
        packageData?: PackageType[] | undefined;
        total?: Totals,
      },
      pageInfo: PageInfoType;
    },
    unitrate?: {
      data: UnitRateType[];
      pageInfo?: PageInfoType;
    };
    wbs?: {
      data: WBSDataType[];
      pageInfo: PageInfoType;
      total_values: TotalValueType;
    };
    wbsdashboard?: {
      data: WBSDataType[];
    };
    wbsphase?: {
      data: ProjectPhase[];
      pageInfo: PageInfoType;
      total_values: TotalValueType;
    };
    wbscase?: {
      data: CaseType[];
      pageInfo: PageInfoType;
      total_values: TotalValueType;
    };
    wbsversion?: {
      data: VersionType[];
      pageInfo: PageInfoType;
      total_values: TotalValueType;
    };
    coa?: {
      data: COADataType[];
      pageInfo: PageInfoType;
      total_values: TotalValueType;
    };
    coadashboard?: {
      data: COADataType[];
    };
    coaphase?: {
      data: ProjectPhase[];
      pageInfo: PageInfoType;
      total_values: TotalValueType;
    };
    coacase?: {
      data: CaseType[];
      pageInfo: PageInfoType;
      total_values: TotalValueType;
    };
    coaversion?: {
      data: VersionType[];
      pageInfo: PageInfoType;
      total_values: TotalValueType;
    };
    project?: {
      data: ProjectListType[];
      pageInfo: PageInfoType;
    };
    projectTotalCount?: {
      project_count: number,
      project_country_count: number,
    }
    commodities_detail?: {
      data: CommoditiesListType[];
      pageInfo?: PageInfoType | undefined;
    };
    schedule?: {
      data: {
        scheduleData: ScheduleType[];
        total?: Totals,
      };
      pageInfo: PageInfoType;
    };
    getUserByEmail?: {
      email: string | null;
    };
    getorganduserprojects?: {
      data: OrgUserProjects[];
      pageInfo: PageInfoType;
      error: null | string;
      error_code: null | string;
    }
    getAllUserDetails?: OrgUserType | undefined;
    user_industries?: IndustriesType[];
    getUserSettingDetails?: UserDetailsType;
    getUserProjectList?: UserProjectsListType[];
    getorguser?: GetOrgUser[];
    getorgprojects?: GetOrgProjectType[];
    getOrganizationList?: {
      data: OrganisationDetailType[] | undefined;
      pageInfo: PageInfoType;
    };
    getuserprojects?: {
      data: UserProjectsListType[] | undefined;
      pageInfo: PageInfoType;
    }
    addInvitation?: {
      error_code: string | null;
      error: string;
    };
    acceptInvitation?: {
      error_code: string | null;
      error: string;
    };
    getUserInvitation?: {
      method: string | null;
      email: string;
    };
    labour_crew_rates?: {
      data: LabourAndCrewType[];
      pageInfo: PageInfoType;
      total_values: TotalValueType;
    };
    estimate_detail?: {
      data?: {
        estimatedData: EstimateType[];
        total: EstimateTotalType;
      };
      pageInfo: PageInfoType;
    };
    reports?: {
      data?: ReportType[];
      pageInfo: PageInfoType;
    }
  }
  | undefined;
  isFetching: boolean;
  isError: boolean;
  refetch: ({ orgId }?: { [key: string]: string | number }, { cancelRefetch } ?: { [key: string]: boolean }) => void;
}

export type QueryReturnData = QueryReturnType['data'];

type Config =
  | {
    enabled?: boolean;
    refetchOnWindowFocus?: boolean;
    onSuccess?: (data: InvitationType) => void,
    onError?: (err: QueryErrorResponse) => void,
    cacheTime?: number,
  }
  | undefined;

type QueryErrorResponse = {
  response: {
    errors: {
      message: string;
      status: number;
    }[];
    status: number;
  };
};

export const useGQLQuery = (
    key: string,
    query: string,
    variables = {},
    config?: Config,
    path?: string,
): QueryReturnType => {
    const apiPath = path || '/list';
    const graphQLClient = gqlConfig(apiPath); // replace with actual access token
    const logout = useLogout();

    const onError = (err: QueryErrorResponse) => {
        const { status, errors } = err.response;
        // Logout for unauthorized users(Status = 401)
        if (status === 401) {
            logout();
            return;
        }

        // Logout for users whose organisation has been deleted(Status 500 and error status 401)
        const orgDisabledError = errors[0].status;
        const orgDisabledMessage = errors[0].message;
        if (status === 500 && orgDisabledError === 401 && orgDisabledMessage === ORG_DISABLED_ERROR_MESSAGE) {
            logout();
            return;
        }

        if (config?.onError) {
            config.onError(err);
        }
    };
    // const fetchData = async () => graphQLClient.request(query, variables);
    const defaultQueryConfig = { refetchOnWindowFocus: false };

    // Disable query if access token is not fetched yet
    const accessToken = getAccessTokenFromLocalStorage();

    const queryConfig = {
        ...config,
        retry: false,
        refetchOnWindowFocus: false,
        enabled:
      config && config.enabled !== undefined ? config.enabled : !!accessToken,
        cacheTime: config && config.cacheTime,
    };

    return useQuery(key, () => fetchData(graphQLClient, query, variables), {
        ...defaultQueryConfig,
        ...queryConfig,
        onError,
    });
};
