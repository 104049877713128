/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {
    useState, useEffect, useContext,
    useMemo,
    useCallback,
    useRef,
} from 'react';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    ValueFormatterParams,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    FilterChangedEvent, SortChangedEvent,
    IsServerSideGroupOpenByDefaultParams,
    RowEvent,
    RowGroupOpenedEvent,
    SetFilterValuesFuncParams,
    IMultiFilter,
    FilterOpenedEvent,
    ColumnState,
    ColumnRowGroupChangedEvent,
    ColumnVO,
    Column,
} from 'ag-grid-community';
import { Box, IconButton } from '@mui/material';
import EstimateStyle from './Estimate.style';
import EstimateHeader from '../../components/EstimateHeader';
import estimateQueries from '../../../../queries/estimate';
import { fetchData, useGQLQuery } from '../../../../hooks/useGQLQuery';
import { DEFAULT_PAGE_SIZE, ROLES } from '../../../../constants';
// import Pagination from '../../../../components/paginationTable';
import Loader from '../../../../components/Loader';
import { OutletContext, QueryErrorResponse } from '../../../../types/CommoditiesListType';
import {
    useOutletContext, useLocation, useNavigate, Link,
    useNavigationType,
} from 'react-router-dom';
import { ProjectContext } from '../../../../store/context/projectContext';
import { UserContext } from '../../../../store/context/userContext';
import AgGridComponent from '../../../../components/agGridTableComponent';
import { CellRendererType, ColumnDefs } from '../../../../types/AgGridTypes';
import { useDisplayCurrFormatter, formatCurr, useFormatCurrency } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';
import { PATH_CMFR } from '../../../../Routes/path';
import getProjectLevel from '../../../../helper/ProjectLevel';
import { useDebounce } from '../../../../hooks/useDebounce';
// import { useQueryClient } from 'react-query';
import gqlConfig from '../../../../helper/gqlConfig';
import { EstimateType, EstimateTotalType } from '../../../../types/EstimateType';
import PageInfoType from '../../../../types/PageInfoType';
import { AgGridReact } from 'ag-grid-react';
import { EstimateTypeExpanded, LevelsRef, UnitRateContext } from '../../../../store/context/userRateIdLevelsData';
import { SetFilter } from 'ag-grid-enterprise';
import UnitRateType from '../../../../types/UnitRateType';
import SaveGridLayout from '../../components/SaveGridLayout';
import gridLayoutQueries from '../../../../queries/gridLayout';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import { useUserRole } from '../../../../hooks/useUserRole';
import { JSONParse } from '../../../../helper/StorageHelper';
// import { OutletContext } from '../../../../types/OrganisationDetailType';

interface CurrentGridLayout {
    id: string;
    org_id: string;
    user_id: string;
    project_id: string;
    grid_layout_id: string;
    created_at: string;
    updated_at: string;
}

interface GridState {
    state: ColumnState[],
    filter: { [key: string]: any },
    getGridLayout?: {
        data?:{
        grid_state?: Column
        }
    }
}

interface EstimateGridLayoutType {
    id?: string;
    grid_state?: string;
    layout_name?: string;
    org_id: string;
    pivot_mode?: boolean;
    project_id?: string;
    created_at: string;
    updated_at: string;
    current_grid_layouts?: CurrentGridLayout[];
}

interface GetEstimateGridLayoutType {
      data?: EstimateGridLayoutType[];
}

interface StateType {
    state?: ColumnState[];
    filter?: Record<string, any>;
}

// interface EstimateDataInterface {
//     description: string,
//     detailed_description: string,
//     quantity: number,
//     ur_code: string,
//     unit_cost: number,
//     total_cost: number,
//     wbs: {
//         description: string,
//         code: string,
//         level: number,
//         code1: string,
//         code2: string
//     },
//     coa: {
//         code: string,
//         level: number,
//         code1: string,
//         code2: string
//     }
// }

interface LocationState {
    estimateFilter?: string;
    code: string;
    description: string;
    gCoaId: string;
    wbsId: string;
    gcoaCode: string;
    gcoaDescription: string;
    industryCode: string;
    industryDescription: string;
}

interface ValueFormatterParamsExtended extends ValueFormatterParams {
    pinned?: string | null;
    handleLink?: (data: unknown) => void;
}

interface EstimateDataTypeForCustomCells extends EstimateType {
    unit_rate_id?: string;
}

function CustomGroupRowRenderer(params: ValueFormatterParamsExtended) {
    const { node } = params;
    const [expanded, setExpanded] = useState(node?.expanded);
    const projectCtx = useContext(ProjectContext);
    const selectedProject = projectCtx?.project;
    const casedata = projectCtx?.projectCaseData;
    const formatCurrency = useDisplayCurrFormatter();
    const wbsLevel = getProjectLevel([
        selectedProject?.wbs1,
        selectedProject?.wbs2,
        selectedProject?.wbs3,
        selectedProject?.wbs4,
        selectedProject?.wbs5,
        selectedProject?.wbs6,
    ]);
    useEffect(() => {
        const expandListener = (event: RowEvent) => setExpanded(event.node?.expanded);
        node?.addEventListener('expandedChanged', expandListener);
        return () => {
            node?.removeEventListener('expandedChanged', expandListener);
        };
    }, []);
    const toggleExpansion = useCallback(() => node?.setExpanded(!node.expanded), [node]);

    const style = {
        height: '100%',
        fontSize: '12px',
        fontWeight: 700,
        color: '#000',
        lineHeight: '27px',
        paddingLeft: '0px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: '',
        paddingRight: '10px',
    };
    const fontWeight400 = {
        fontWeight: 400,
    };

    if (params?.columnApi.isPivotMode()) {
        return (
            <div>
                &nbsp;
                {node?.key || node?.parent?.key || node?.parent?.parent?.key || node?.parent?.parent?.parent?.key}
            </div>
        );
    }

    if (!node?.group) {
        const data: EstimateDataTypeForCustomCells = node?.data;
        if (data?.unit_rate_id === null || !data?.description) {
            return (
                <span style={{
                    paddingLeft: '10px',
                }}
                >
                    {data?.description.toLocaleString()}
                </span>
            );
        }
        return (
            <Link
                to="/"
                style={{
                    paddingLeft: '10px',
                }}
                onClick={(e) => {
                    e.preventDefault();
                    if (params?.handleLink) params?.handleLink(data);
                }}
                // className={classes.boldText}
            >
                {data?.description.toLocaleString()}
            </Link>
        );
    }

    if (params?.pinned) {
        return (
            <div
                style={style}
                onClick={toggleExpansion}
                onKeyDown={toggleExpansion}
                role="button"
                tabIndex={0}
            >
                {node?.group && (
                    <div
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={toggleExpansion}
                        onKeyDown={toggleExpansion}
                        role="button"
                        tabIndex={0}
                        aria-label="Toggle Button"
                    />
                )}
            </div>
        );
    }

    return (
        <div
            style={style}
            onClick={toggleExpansion}
            onKeyDown={toggleExpansion}
            role="button"
            tabIndex={0}
        >
            {node?.group && (
                <div
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={toggleExpansion}
                    onKeyDown={toggleExpansion}
                    role="button"
                    tabIndex={0}
                    aria-label="Toggle Button"
                >
                    <KeyboardArrowRightIcon
                        style={{
                            transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                        }}
                        onClick={toggleExpansion}
                    />
                </div>
            )}
            &nbsp;
            {node?.key || node?.parent?.key || node?.parent?.parent?.key || node?.parent?.parent?.parent?.key}
            &nbsp;
            {/* {(node?.data as EstimateType).total_cost
                && (
                    <div style={fontWeight400}>
                        &nbsp;&nbsp;
                        {formatCurrency((node?.data as EstimateType).total_cost.toFixed(0))}
                    </div>
                )} */}
        </div>
    );
}

interface Data {
    estimatedData: EstimateType[];
    total: EstimateTotalType;
}

interface ResponseEstimateDetail {
    estimate_detail?: {
        data?: {
          estimatedData: EstimateType[];
          total: EstimateTotalType;
        };
        pageInfo: PageInfoType;
    };
}

interface ResponseSetFiltersEstimate {
    estimate_detail_set_filters?: {
        data?: string[];
    }
}

interface ColumnDefsButWithSort extends ColumnDefs {
    initialSort?: 'asc' | 'desc' | null,
    initialRowGroup?: boolean,
    enableValue?: boolean,
    allowedAggFuncs?: string[],
    initialSortIndex?: number,
    enableRowGroup?: boolean,
    filterParams?: any,
    suppressFiltersToolPanel?: boolean,
}

interface FilterOpenEventColumn {
    userProvidedColDef?: {
        field?: string
    }
}

interface FilterModelType {
    'wbs.code'?: {
        filterModels: any[]
    },
    'coa.code'?: {
        filterModels: any[]
    },
    'case.code'?: {
        filterModels: any[]
    },
    'phase.code'?: {
        filterModels: any[]
    },
    ur_code?: {
        filterModels: any[]
    },
}

interface RowGroupCol {
    aggFunc?: string;
    id?: string;
    displayName?: string;
    field?: string;
}

export default function Estimate() {
    const ref = useRef<AgGridReact>(null);
    const classes = EstimateStyle();
    // const [pageSkipValue, setPageSkipValue] = useState(0);
    const cont: OutletContext = useOutletContext();
    const projectCtx = useContext(ProjectContext);
    const userCtx = useContext(UserContext);
    const formatCurrency = useDisplayCurrFormatter();
    const currenctFormat = useFormatCurrency();
    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate;
    const loadingRenderer = true;
    const [searchText, setSearchText] = useState<string>('');
    const debouncingValue = useDebounce(searchText);
    const [stateText, setStateText] = useState<string>('false');
    const debouncedText = useDebounce(stateText);
    const [filters, setFilters] = useState<string>('');
    const [sortModel, setSortModel] = useState<string>('');
    const [rowGroupModel, setRowGroupModel] = useState<string>('[]');
    const [groupKeys, setGroupKeys] = useState<string>('');
    const [canRenderClientSide, setCanRenderClientSide] = useState<boolean>(false);
    const [gridLayoutData, setGridLayoutData] = useState<GetEstimateGridLayoutType | any>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [isPivotActive, setIsPivotActive] = useState<boolean>(false);
    const [valueColsLength, setValueColsLength] = useState<number>(0);
    // const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();
    const loggedInUserRole = useUserRole();
    const [gridState, setGridState] = useState<StateType>();
    const [gridView, setGridView] = useState<string>('');
    const [estimateFilterId, setEstimateFilterId] = useState<string | undefined>(
        (location.state as LocationState)?.estimateFilter,
    );
    const [estimateFilterWbsId, setEstimateFilterWbsId] = useState<string | undefined>(
        (location.state as LocationState)?.wbsId,
    );
    const [estimateFilterGCoaId, setEstimateFilterGCoaId] = useState<string | undefined>(
        (location.state as LocationState)?.gCoaId,
    );
    const estimateFilterIdRef = useRef<string | undefined>((location.state as LocationState)?.estimateFilter);
    const estimateFilterWbsIdRef = useRef<string | undefined>((location.state as LocationState)?.wbsId);
    const estimateFilterGCoaIdRef = useRef<string | undefined>((location.state as LocationState)?.gCoaId);
    const userRole = ROLES.find((role) => role.id === loggedInUserRole);
    const {
        code, description, gcoaCode, gcoaDescription, industryCode, industryDescription,
    } = (location.state || {}) as LocationState;
    const selectedProject = projectCtx?.project;
    const local:string = CURR_FORMAT[selectedProject?.currency || 'USD'];
    const levelsRef = useRef<LevelsRef>({});
    const groupKeysRef = useRef<string[]>([]);
    const rowGroupRef = useRef<RowGroupCol[]>([]);
    const valueColRef = useRef<RowGroupCol[]>([]);
    const unitRateIdAssociationRef = useRef<string>('');
    const UnitRateIdRowDataCtx = useContext(UnitRateContext);
    const navigation = useNavigationType();
    const [layoutName, setLayoutName] = useState<string>();
    const unitRateIdRef = useRef<string | null>(null);

    const handleLink = (p: UnitRateType) => {
        unitRateIdAssociationRef.current = p?.unit_rate_id as string;
        if (p?.unit_rate_id) {
            UnitRateIdRowDataCtx.setUnitRateId(p?.unit_rate_id || null);
            unitRateIdRef.current = p?.unit_rate_id || null;
        } else {
            UnitRateIdRowDataCtx.setUnitRateId('');
            unitRateIdRef.current = '';
        }
        UnitRateIdRowDataCtx.setLevelsData(levelsRef.current);
        navigate(PATH_CMFR.capex.unit, { state: { unitRateId: p?.unit_rate_id, coaCde: p?.coa?.code } });
    };

    // Function to return blanks to cells for row group aggregation when pivot mode is not active
    const rowGroupCellRendererCheck = (params: CellRendererType) : boolean => {
        const { value } = params;

        if ((params.node.expanded === false || params.node.expanded)
            && ((
                rowGroupRef.current.length > groupKeysRef.current.length
                && (
                    (params.colDef.type !== 'numericColumn')
                    || (params.colDef.type === 'numericColumn'
                        && (!value && value !== 0)
                    )
                )
            ) || (
                rowGroupRef.current.length === groupKeysRef.current.length
                && params.node?.level < rowGroupRef.current.length
                && (
                    (params.colDef.type !== 'numericColumn')
                    || (params.colDef.type === 'numericColumn'
                        && (!value && value !== 0)
                    )
                )
            ))
        ) {
            return true;
        }
        return false;
    };

    const rowCellRenderer = (params: CellRendererType) => {
        const { value } = params;
        const colName = params.colDef.field;

        // Condition for row group aggregation when pivot mode is not active
        if (rowGroupCellRendererCheck(params)) {
            return '';
        }

        if (value != null) {
            if (params?.node?.rowPinned) {
                return (
                    <span>
                        {params?.value.toLocaleString()}
                    </span>
                );
            }
            if (value && params.colDef.isDelimiter) {
                return formatCurr(+value.toFixed(0), local);
            }

            switch (colName) {
            case 'labour_cost':
            case 'construction_equip_cost':
            case 'material_cost':
            case 'permanent_equip_cost':
            case 'subcontract_cost':
            case 'indirect_cost':
            case 'total_cost':
                return params.value || params.value === 0 ? formatCurrency(params.value.toFixed(0)) : '-';
            case 'total_unit_cost':
            case 'unit_labour_cost':
            case 'unit_consequip_cost':
            case 'unit_material_cost':
            case 'unit_subcontract_cost':
            case 'unit_indirect_cost':
            case 'unit_pequipment_cost':
                return params.value || params.value === 0 ? formatCurrency(params.value.toFixed(2)) : '-';
            case 'quantity':
            case 'base_quantity':
            case 'prod_factor':
                return params.value || params.value === 0 ? formatCurr(params.value.toFixed(2), local) : '-';
            case 'unit_man_hours':
                return params.value || params.value === 0 ? formatCurr(params.value.toFixed(3), local, 3) : '-';
            case 'design_growth':
                return params.value || params.value === 0 ? `${formatCurr((params.value * 100).toFixed(2), local)}%` : '-';
            case 'wastage_factor':
                return params.value || params.value === 0 ? `${formatCurr((params.value * 100).toFixed(1), local, 1)}%` : '-';
            case 'description':
                if (params?.data?.unit_rate_id == null || !params?.value) {
                    return (
                        <span>
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                return (
                    <Link
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            handleLink(params?.data);
                        }}
                        // className={classes.boldText}
                    >
                        {params?.value.toLocaleString()}
                    </Link>
                );
            case 'markup_labour':
            case 'markup_construction_equip':
            case 'markup_material':
            case 'markup_permanent_equip':
            case 'markup_subcontract':
                return params.value || params.value === 0 ? `${formatCurr((value * 100).toFixed(1), local, 1)}%` : '-';
            default:
                return value;
            }
        } else if (value === null) {
            return '-';
        }
        return '';
    };

    const handleApiError = ({ response }: QueryErrorResponse) => {
        // setGridView('');
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const { data: estimateGridLayoutState, isFetching: isFetchingEstimate, refetch } = useGQLQuery(
        'GetEstimateGridLayout',
        gridLayoutQueries.GET_GRID_ESTIMATE_LAYOUT(
            userCtx?.user?.default_org_id || '',
        ),
        {},
        {},
    );

    useEffect(() => {
        if (gridView === 'Default View') {
            ref?.current?.columnApi.setPivotMode(false);
        }
    }, [gridView]);

    useEffect(() => {
        if (estimateGridLayoutState?.getEstimateGridLayout?.data?.length) {
            const currentLayout = estimateGridLayoutState?.getEstimateGridLayout?.data?.find((layouts) => layouts.current_grid_layouts?.some(
                (grid) => grid.user_id === userCtx?.user?.user_id
                && grid.project_id === projectCtx?.project?.id
                && grid.org_id === userCtx?.user?.default_org_id,
            ));
            if (currentLayout) {
                setGridView?.(currentLayout.layout_name as string);
            } else {
                setGridView?.('Default View');
            }
        }
    }, [estimateGridLayoutState?.getEstimateGridLayout?.data]);

    const { mutate, isLoading: saveEstimateGridLoading } = useGQLMutation(
        'SaveGridLayout',
        gridLayoutQueries.SAVE_ESTIMATE_GRID_LAYOUT,
        {
            onError: handleApiError,
            onSuccess: () => refetch(),
        },
        '/list',
    );

    const { mutate: resetMultipleEstimateMutate, isLoading: isResetMultipleEstimateLoading } = useGQLMutation(
        'resetMultipleEstimateGrids',
        gridLayoutQueries.RESET_MULTIPLE_ESTIMATE_GRID_LAYOUT,
        {},
        '/list',
    );

    const { mutate: resetDefaultViewEstimateMutate, isLoading: isDefaultViewEstimateLoading } = useGQLMutation(
        'resetDefaultViewEstimateGrids',
        gridLayoutQueries.DEFAULT_VIEW_ESTIMATE_GRID_LAYOUT,
        {
            onSuccess: () => refetch(),
        },
        '/list',
    );

    const { mutate: deleteMutate, isLoading: isEstimateResetLoading } = useGQLMutation(
        'deleteEstimateGridLayout',
        gridLayoutQueries.DELETE_ESTIMATE_GRID_LAYOUT,
        {
            onSuccess: () => refetch(),
        },
        '/list',
    );

    const handleOpen = (p: boolean) => {
        setOpen(p);
        setLayoutName('');
    };

    const fetchRef = useRef<boolean>(false);
    const countRef = useRef<number | null>(null);
    const filterRef = useRef<string | null>(null);
    const searchRef = useRef<string | null>(null);
    const exRateRef = useRef<number | null>(null);
    const canRenderRef = useRef<boolean>(true);
    const getSetFilterValues = (params: SetFilterValuesFuncParams) : void => {
        const query = estimateQueries.GET_ESTIMATE_SET_FILTERS(
            JSON.stringify(params.colDef),
            userCtx?.user?.default_org_id || '',
            selectedProject?.id || '',
            selectedProject?.version_id || '',
            searchRef?.current || '',
            filterRef?.current || '',
            estimateFilterIdRef.current || '',
            estimateFilterGCoaIdRef.current || '',
            estimateFilterWbsIdRef.current || '',
            exRateRef.current || 1,
        );
        const graphQLClient = gqlConfig('/list');

        if ((!countRef?.current && !fetchRef?.current) || canRenderRef?.current) {
            fetchData(graphQLClient, query, {}).then((unknownResponse: unknown) => {
                const response = unknownResponse as ResponseSetFiltersEstimate;
                if (response?.estimate_detail_set_filters?.data) params?.success(response?.estimate_detail_set_filters?.data);
            }).catch((err: Error) => {
                cont.showNotificationBar(err.message.substring(0, 60), 'error');
            });
        }
    };

    const refreshSetFilters = (event: FilterChangedEvent) => {
        const oldFilters = JSON.parse(filterRef.current || '{}') as FilterModelType;

        const wbscode = (event.api.getFilterModel() as FilterModelType)['wbs.code']?.filterModels[0];
        if (
            (wbscode
            && (
                (oldFilters['wbs.code']?.filterModels[0] && JSON.stringify(wbscode) !== JSON.stringify(oldFilters['wbs.code']?.filterModels[0]))
                || (!oldFilters['wbs.code']?.filterModels[0])
            ))
            || (!wbscode && oldFilters['wbs.code']?.filterModels[0])
        ) {
            (ref?.current?.api?.getFilterInstance<IMultiFilter>('wbs.code')?.getChildFilterInstance(1) as SetFilter).refreshFilterValues();
        }

        const coacode = (event.api.getFilterModel() as FilterModelType)['coa.code']?.filterModels[0];
        if (
            (coacode
            && (
                (oldFilters['coa.code']?.filterModels[0] && JSON.stringify(coacode) !== JSON.stringify(oldFilters['coa.code']?.filterModels[0]))
                || (!oldFilters['coa.code']?.filterModels[0])
            ))
            || (!coacode && oldFilters['coa.code']?.filterModels[0])
        ) {
            (ref?.current?.api?.getFilterInstance<IMultiFilter>('coa.code')?.getChildFilterInstance(1) as SetFilter).refreshFilterValues();
        }

        const casecode = (event.api.getFilterModel() as FilterModelType)['case.code']?.filterModels[0];
        if (
            (casecode
            && (
                (oldFilters['case.code']?.filterModels[0] && JSON.stringify(casecode) !== JSON.stringify(oldFilters['case.code']?.filterModels[0]))
                || (!oldFilters['case.code']?.filterModels[0])
            ))
            || (!casecode && oldFilters['case.code']?.filterModels[0])
        ) {
            (ref?.current?.api?.getFilterInstance<IMultiFilter>('case.code')?.getChildFilterInstance(1) as SetFilter).refreshFilterValues();
        }

        const phasecode = (event.api.getFilterModel() as FilterModelType)['phase.code']?.filterModels[0];
        if (
            (phasecode
            && (
                (oldFilters['phase.code']?.filterModels[0] && JSON.stringify(phasecode) !== JSON.stringify(oldFilters['phase.code']?.filterModels[0]))
                || (!oldFilters['phase.code']?.filterModels[0])
            ))
            || (!phasecode && oldFilters['phase.code']?.filterModels[0])
        ) {
            (ref?.current?.api?.getFilterInstance<IMultiFilter>('phase.code')?.getChildFilterInstance(1) as SetFilter).refreshFilterValues();
        }

        const urcode = (event.api.getFilterModel() as FilterModelType)?.ur_code?.filterModels[0];
        if (
            (urcode
            && (
                (oldFilters?.ur_code?.filterModels[0] && JSON.stringify(urcode) !== JSON.stringify(oldFilters?.ur_code?.filterModels[0]))
                || (!oldFilters?.ur_code?.filterModels[0])
            ))
            || (!urcode && oldFilters?.ur_code?.filterModels[0])
        ) {
            (ref?.current?.api?.getFilterInstance<IMultiFilter>('ur_code')?.getChildFilterInstance(1) as SetFilter).refreshFilterValues();
        }
    };

    useEffect(() => {
        setCanRenderClientSide(() => false);
        filterRef.current = filters;
        searchRef.current = debouncingValue;
        exRateRef.current = (1 / (exchangeRate || 1));
    }, [filters, debouncingValue, exchangeRate]);

    const onFilterOpens = (event: FilterOpenedEvent) => {
        const fieldName = (event.column as FilterOpenEventColumn)?.userProvidedColDef?.field;
        if (
            fieldName === 'wbs.code'
            && ref?.current?.api?.getFilterInstance<IMultiFilter>('wbs.code')
        ) {
            (ref?.current?.api?.getFilterInstance<IMultiFilter>('wbs.code')?.getChildFilterInstance(1) as SetFilter).refreshFilterValues();
        }

        if (
            fieldName === 'coa.code'
            && ref?.current?.api?.getFilterInstance<IMultiFilter>('coa.code')
        ) {
            (ref?.current?.api?.getFilterInstance<IMultiFilter>('coa.code')?.getChildFilterInstance(1) as SetFilter).refreshFilterValues();
        }

        if (
            fieldName === 'case.code'
            && ref?.current?.api?.getFilterInstance<IMultiFilter>('case.code')
        ) {
            (ref?.current?.api?.getFilterInstance<IMultiFilter>('case.code')?.getChildFilterInstance(1) as SetFilter).refreshFilterValues();
        }

        if (
            fieldName === 'phase.code'
            && ref?.current?.api?.getFilterInstance<IMultiFilter>('phase.code')
        ) {
            (ref?.current?.api?.getFilterInstance<IMultiFilter>('phase.code')?.getChildFilterInstance(1) as SetFilter).refreshFilterValues();
        }

        if (
            fieldName === 'ur_code'
            && ref?.current?.api?.getFilterInstance<IMultiFilter>('ur_code')
        ) {
            (ref?.current?.api?.getFilterInstance<IMultiFilter>('ur_code')?.getChildFilterInstance(1) as SetFilter).refreshFilterValues();
        }
    };

    useEffect(() => {
        if (canRenderClientSide) {
            setCanRenderClientSide(() => false);
        }
        if (!isPivotActive && valueColsLength) ref?.current?.columnApi?.setValueColumns([]);
        else if (isPivotActive && !ref?.current?.columnApi?.getRowGroupColumns().length) {
            ref?.current?.columnApi?.resetColumnState();
        }
    }, [isPivotActive]);

    const columnDefs: ColumnDefsButWithSort[] = [
        {
            field: 'wbs.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'WBS',
            cellRenderer: rowCellRenderer,
            initialSort: 'asc',
            enableValue: false,
            initialSortIndex: 2,
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: getSetFilterValues,
                        },
                    },
                ],
            },
        },
        {
            field: 'coa.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'COA',
            // initialRowGroup: true,
            cellRenderer: rowCellRenderer,
            suppressMenu: false,
            sortable: true,
            initialSort: 'asc',
            enableValue: false,
            initialSortIndex: 3,
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: getSetFilterValues,
                        },
                    },
                ],
            },
        },
        {
            field: 'coa.self_code_desc',
            type: 'string',
            initialWidth: 130,
            headerName: 'COA Self Desc',
            suppressMenu: true,
            sortable: false,
            cellRenderer: rowCellRenderer,
            initialHide: true,
            cellRendererParams: {
                suppressCount: true,
            },
            suppressToolPanel: true,
            suppressFiltersToolPanel: true,
            enableValue: false,
        },
        {
            field: 'case.code',
            type: 'string',
            initialWidth: 130,
            headerName: 'Case',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            suppressMenu: false,
            sortable: true,
            enableRowGroup: false,
            enableValue: false,
            initialSort: 'asc',
            initialSortIndex: 1,
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: getSetFilterValues,
                        },
                    },
                ],
            },
        },
        {
            field: 'phase.code',
            type: 'string',
            enableValue: false,
            initialWidth: 130,
            headerName: 'Phase',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            initialSort: 'asc',
            initialSortIndex: 4,
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: getSetFilterValues,
                        },
                    },
                ],
            },
        },
        {
            field: 'phase.name',
            type: 'string',
            enableValue: false,
            initialWidth: 130,
            headerName: 'Phase Desc',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'package_labour.code',
            type: 'string',
            enableValue: false,
            initialWidth: 130,
            headerName: 'Lab Pkg',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'package_construction.code',
            type: 'string',
            enableValue: false,
            initialWidth: 130,
            headerName: 'Ceq Pkg',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'package_material.code',
            type: 'string',
            enableValue: false,
            initialWidth: 130,
            headerName: 'Mat Pkg',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'package_permanent.code',
            type: 'string',
            enableValue: false,
            initialWidth: 130,
            headerName: 'Eqp Pkg',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'package_subcontract.code',
            type: 'string',
            enableValue: false,
            initialWidth: 130,
            headerName: 'Sub Pkg',
            // initialRowGroup: true,
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'package_indirect.code',
            type: 'string',
            enableValue: false,
            initialWidth: 130,
            headerName: 'Ind Pkg',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_rate.code',
            type: 'string',
            enableValue: false,
            initialWidth: 130,
            headerName: 'Unit Rate',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'class',
            type: 'string',
            initialWidth: 160,
            headerName: 'Class',
            cellRenderer: rowCellRenderer,
            enableValue: false,
        },
        {
            field: 'item_type',
            type: 'string',
            enableValue: false,
            initialWidth: 130,
            headerName: 'Type',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'sequence',
            type: 'string',
            enableValue: false,
            initialWidth: 130,
            headerName: 'Item',
            cellRenderer: rowCellRenderer,
            initialSort: 'asc',
            initialSortIndex: 5,
        },
        {
            field: 'description',
            type: 'string',
            enableValue: false,
            initialWidth: 300,
            headerName: 'Description',
            cellRenderer: rowCellRenderer,
            initialHide: !!ref?.current?.columnApi?.getRowGroupColumns()?.length,
            // cellRendererFramework: rowCellRenderer,
        },
        {
            field: 'detailed_description',
            type: 'string',
            enableValue: false,
            initialWidth: 300,
            headerName: 'Description2',
            cellRenderer: rowCellRenderer,
        },
        {
            field: 'ur_code',
            type: 'string',
            initialWidth: 160,
            headerName: 'Unit',
            cellRenderer: rowCellRenderer,
            filter: 'agMultiColumnFilter',
            enableValue: false,
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: getSetFilterValues,
                        },
                    },
                ],
            },
        },
        {
            field: 'base_quantity',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Base Quantity',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'design_growth',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Design Growth',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'quantity',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Quantity',
            cellRenderer: rowCellRenderer,
        },
        {
            field: 'prod_factor',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Prod Factor',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'wastage_factor',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Wastage',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'commodities.self_code_desc',
            type: 'string',
            enableValue: false,
            initialWidth: 160,
            headerName: 'gCoa Self Desc',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            suppressMenu: true,
            sortable: false,
            suppressFiltersToolPanel: true,
        },
        {
            field: 'markup_labour',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Lab Markup',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'markup_construction_equip',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Ceq Markup',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'markup_material',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Mat Markup',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'markup_permanent_equip',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Eqp Markup',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'markup_subcontract',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Sub Markup',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_man_hours',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Unit Man Hours',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_labour_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Unit Labour',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_consequip_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Unit C.Equip',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_material_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Unit Material',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_pequipment_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Unit Equipment',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_subcontract_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Unit Subcontract',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'unit_indirect_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Unit Indierct',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'labour_hours',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: true,
            initialWidth: 160,
            headerName: 'Labour Hours',
            cellRenderer: rowCellRenderer,
            initialHide: true,
            isDelimiter: true,
        },
        {
            field: 'labour_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: true,
            initialWidth: 160,
            headerName: 'Labour',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'construction_equip_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: true,
            initialWidth: 160,
            headerName: 'C.Equipment',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'material_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: true,
            initialWidth: 160,
            headerName: 'Material',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'permanent_equip_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: true,
            initialWidth: 160,
            headerName: 'Equipment',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'subcontract_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: true,
            initialWidth: 160,
            headerName: 'Subcontract',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'indirect_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: true,
            initialWidth: 160,
            headerName: 'Indirect',
            cellRenderer: rowCellRenderer,
            initialHide: true,
        },
        {
            field: 'total_cost',
            type: 'numericColumn',
            initialWidth: 160,
            headerName: 'Total Cost',
            cellRenderer: rowCellRenderer,
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: true,
        },
        {

            field: 'total_unit_cost',
            type: 'numericColumn',
            allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg'],
            enableValue: isPivotActive,
            initialWidth: 160,
            headerName: 'Total Unit Cost',
            enableRowGroup: false,
            cellRenderer: rowCellRenderer,
            suppressToolPanel: false,
            cellRendererParams: {
                suppressCount: false,
            },
        },
    ];

    useEffect(() => {
        // Update the ref value on page refresh from the context value, this is required since shift from SSide to CSide will clear Ref
        if (UnitRateIdRowDataCtx?.unitRateId && !unitRateIdRef.current) {
            unitRateIdRef.current = UnitRateIdRowDataCtx?.unitRateId;
        }
    }, []);

    const [newColumnDefs, setNewColumnDefs] = useState<ColumnDefsButWithSort[]>(columnDefs);
    // const handleApiError = ({ response }: QueryErrorResponse) => {
    //     const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
    //     cont.showNotificationBar(message, 'error');
    // };

    // const { data, isFetching, refetch } = useGQLQuery(
    //     `GetEstimateDetails-${selectedProject?.id || ''}`,
    //     estimateQueries.GET_ESTIMATE_DETAILS(
    //         pageSkipValue,
    //         DEFAULT_PAGE_SIZE,
    //         userCtx?.user?.default_org_id || '',
    //         selectedProject?.id || '',
    //         selectedProject?.version_id || '',
    //         estimateFilter || '',
    //         debouncingValue,
    //         filters,
    //         sortModel,
    //         rowGroupModel,
    //         groupKeys,
    //         exchangeRate || 1,
    //     ),
    //     {},
    //     {
    //         onError: handleApiError,
    //     },
    // );

    // const estimateData = data?.estimate_detail;
    // const estData = estimateData?.data;
    // const tableData = estData?.estimatedData;
    // const totalValue = estData?.total;
    // const pageInfo = estimateData?.pageInfo;
    // const rowData = tableData && tableData.length ? tableData : [];
    // const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    // let footerData;
    // if (totalValue) {
    //     footerData = [{
    //         // wbs: 'Selected(0)',
    //         description: `Total Man Hours : ${totalValue?.total_man_hours?.toFixed(0)}`,
    //         detailed_description: `L Cost : ${(currenctFormat(totalValue?.labour_cost?.toFixed(0)))}`,
    //         quantity: `CE Cost : ${currenctFormat(totalValue?.construction_equip_cost?.toFixed(0))}`,
    //         ur_code: `M Cost : ${currenctFormat(totalValue?.material_cost?.toFixed(0))}`,
    //         total_cost: `E Cost : ${currenctFormat(totalValue?.equipment_cost?.toFixed(0))}`,
    //         total_unit_cost: `T Cost : ${currenctFormat(totalValue?.total_cost?.toFixed(0))}`,
    //     }];
    // }

    // const handlePageChange = (page: number) => {
    //     const skip = (page - 1) * DEFAULT_PAGE_SIZE;
    //     setPageSkipValue(skip);
    // };

    // const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setSearchText(e.target.value);
    // };

    // useEffect(() => {
    //     // refetch({ cancelRefetch: isFetching });
    // }, [pageSkipValue]);

    useEffect(() => {
        if (navigation === 'POP' && UnitRateIdRowDataCtx.unitRateId) {
            setCanRenderClientSide(() => true);
        }
    }, []);

    const [data, setData] = useState<Data | null>(null);
    const addColsForAttr = (estimates: Data) : Data => {
        const estData = JSON.parse(JSON.stringify(estimates)) as Data;
        if (estData?.estimatedData && estData?.estimatedData?.length) {
            estData?.estimatedData?.forEach((dt: EstimateType, index: number) => {
                const d = JSON.parse(JSON.stringify(dt)) as EstimateType;
                if (d.labels && typeof d.labels === 'string') {
                    d.labels = JSON.parse(d.labels);
                    if (typeof d.labels === 'object' && d.labels !== null) {
                        let labels = {};
                        labels = d.labels;
                        estData.estimatedData[index] = { ...d, ...labels };
                    }
                }
            });
            setData(() => estData);
        }
        return estData;
    };

    const [canRender, setCanRender] = useState<boolean>(false);
    useEffect(() => {
        const isWbsPresent = newColumnDefs.find((cols) => cols.field === 'wbs.self_code_desc');
        if (isWbsPresent) {
            setCanRender(() => true);
        }
    }, [newColumnDefs]);

    useEffect(() => {
        refetch();
    }, [isResetMultipleEstimateLoading]);

    const clearAssociationRef = useRef<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [footerData, setFooterData] = useState<string | null>(null);
    const [count, setCount] = useState<number>(0);

    const onRowGroupOpened = useCallback((event: RowGroupOpenedEvent) => {
        let name = '';
        let groupName = '';
        const dataLevel = event.node.level;

        let currentNode = event.node;
        let levelCount = dataLevel;

        while (levelCount > 0 && currentNode?.parent) {
            groupName += currentNode.parent.key;
            currentNode = currentNode.parent;
            levelCount -= 1;
        }

        name = currentNode?.key as string;

        const level = dataLevel ? `${name}_L${dataLevel}_${groupName}` : 'topLevel';

        const isExpanded = event.node.expanded;
        // Commented, since sudden shift from client to server side was not required on new rowGroup open
        // if (UnitRateIdRowDataCtx.unitRateId && Object.keys(UnitRateIdRowDataCtx.levelsData).length) {
        //     if (!(event.node.data as EstimateTypeExpanded).isExpanded) {
        //         setCanRenderClientSide(() => false);
        //     } else {
        //         setCanRenderClientSide(() => true);
        //     }
        // }

        // On CSide rowGroup open event, the levels Ref will be lost when navigating back from unitrate,hence appended data from context
        if (unitRateIdRef.current) {
            levelsRef.current = UnitRateIdRowDataCtx.levelsData;
        }

        if (levelsRef.current[level]) {
            levelsRef.current[level].values?.map((item) => {
                const { id } = rowGroupRef.current[dataLevel];
                const table = (id as string).split('.');
                const tableName = table[0] as keyof EstimateType;
                const fieldName = table[1];
                const firstpart = item[tableName] as keyof (EstimateType['wbs'] | EstimateType['coa']);
                const currentNodeKey = firstpart[fieldName as keyof typeof firstpart];

                const matchingRecord = currentNodeKey === event.node.key;
                if (matchingRecord) {
                    // eslint-disable-next-line no-param-reassign
                    item.isExpanded = isExpanded;
                    return item;
                }
                return item;
            });
            levelsRef.current[level].expanded = isExpanded;
        }
    }, []);

    function checkRowGroupsEqual(cols1: ColumnVO[], cols2: ColumnVO[]): boolean {
        if (cols1.length !== cols2.length) return false;
        let isMatching = true;
        const map = new Map<string, ColumnVO>();

        Array.from(cols1).forEach((col) => map.set(col.id, col));

        Array.from(cols2).forEach((col) => {
            const matchingCol = map.get(col.id);
            if (
                !matchingCol
            || matchingCol.displayName !== col.displayName
            || matchingCol.field !== col.field
            || matchingCol.aggFunc !== col.aggFunc
            ) {
                isMatching = false;
            }
        });

        return isMatching;
    }

    const getRows = useCallback((params: IServerSideGetRowsParams) => {
        if (debouncingValue.length) setCanRenderClientSide(() => false);
        const areRowGroupsEqual = checkRowGroupsEqual(rowGroupRef.current as ColumnVO[], params.request.rowGroupCols);
        if (!areRowGroupsEqual && !canRenderClientSide) {
            levelsRef.current = {};
        }
        rowGroupRef.current = params.request.rowGroupCols;
        valueColRef.current = params.request.valueCols;
        groupKeysRef.current = params.request.groupKeys;
        let groupingLength = params.request.groupKeys.length;
        let groupingCols = '';
        while (groupingLength > 0) {
            groupingCols += params.request.groupKeys[groupingLength - 1];
            groupingLength -= 1;
        }

        if (!UnitRateIdRowDataCtx.unitRateId || (!canRenderClientSide && UnitRateIdRowDataCtx?.unitRateId)) {
            UnitRateIdRowDataCtx.setUnitRateId('');
            UnitRateIdRowDataCtx.setLevelsData({});
            UnitRateIdRowDataCtx.setRowData([]);
            UnitRateIdRowDataCtx.setValueColsData([]);
            setCanRenderClientSide(() => false);
        }

        const currentLevel = params.request.groupKeys[0]
            ? `${params.request.groupKeys[0]}_L${params.request.groupKeys.length}_${groupingCols}` : 'topLevel';

        setIsPivotActive(params?.request?.pivotMode);

        if (params?.request?.pivotMode && !params?.request?.rowGroupCols.length) {
            setIsFetching(() => true);
        }

        if (
            (params?.request?.pivotMode && params?.request?.valueCols.length)
            || (!params?.request?.pivotMode)
            || (params?.request?.pivotMode && params?.request?.rowGroupCols.length)
        ) {
            setIsFetching(() => true);
            setCount((prev) => prev + 1);
            const rowDataClientSide = UnitRateIdRowDataCtx.levelsData[currentLevel]?.values as EstimateTypeExpanded[];
            setValueColsLength(() => params?.request?.valueCols.length);

            if (((unitRateIdAssociationRef.current || (UnitRateIdRowDataCtx.unitRateId && navigation === 'POP'))
            && rowDataClientSide
            && (params?.request?.startRow as number < rowDataClientSide.length)
            && !debouncingValue.length && canRenderClientSide
            && (UnitRateIdRowDataCtx.levelsData[currentLevel].values as EstimateTypeExpanded[] || []).length)
            ) {
                setIsFetching(() => true);
                // This will get the count of the rows already rendered
                const allRowsCount = ref.current?.api?.getModel()?.getRowCount() as number;
                const gridLayoutDatas = estimateGridLayoutState?.getEstimateGridLayout?.data;
                const currentGridLayout = gridLayoutDatas?.find((item: EstimateGridLayoutType) => item?.layout_name === gridView);

                // This function will check if the valueCols have changed already
                const checkValueColsChanged = (
                    requestValueCols: ColumnVO[],
                    gridStateValueCols: ColumnState[],
                ): boolean => {
                    // This condition is required just to be sure if the SS and CS is not having valueCols for default view to compare fast
                    if (!UnitRateIdRowDataCtx.valueColsData.length && !requestValueCols.length) {
                        return false;
                    }

                    // Since comparison has to happen between the column name and agg, colId and agg is stored to map for easy access and comparison
                    const requestColsMap = new Map(
                        // Only filters Agg containing values in request.valueCols and stores in map
                        requestValueCols
                            .filter((col) => col.aggFunc)
                            .map((col) => [col.id, { id: col.id, aggFunc: col.aggFunc || null }]),
                    );

                    // We need to track the Grid value Cols as well for comparison required when we delete valueCols and available in grid state
                    const gridColsMap = new Map(
                        // Only filters Agg containing values in request.valueCols and stores in map
                        gridStateValueCols
                            .filter((col) => col.aggFunc)
                            .map((col) => [col.colId, { colId: col.colId, aggFunc: col.aggFunc || null }]),
                    );

                    return (
                        // Check if any column in grid state is missing in request or has a mismatched aggFunc
                        Array.from(gridColsMap.keys()).some((colId) => {
                            const gridCol = gridColsMap.get(colId);
                            const requestCol = requestColsMap.get(colId);
                            // If the Value Cols in request is not present or if the agg doesnt match then it returns true else false
                            return !requestCol || gridCol?.aggFunc !== requestCol.aggFunc;
                        })
                        // Check if any column in request is missing in grid state
                        || Array.from(requestColsMap.keys()).some((colId) => {
                            const requestCol = requestColsMap.get(colId);
                            const gridStateCol = gridStateValueCols.find((col) => col.colId === colId);
                            // If the grid State value Cols is not present or if the agg doesnt match then it returns true else false
                            return !gridStateCol || requestCol?.aggFunc !== gridStateCol.aggFunc;
                        })
                    );
                };

                const gridStates = JSONParse<GridState>(currentGridLayout?.grid_state)?.state || [];
                const hasValueColsChanged = checkValueColsChanged(params.request.valueCols || [], gridStates);

                // After the data is loaded to the grid then we check for any change in row group and value cols to shift to SSide
                if (((ref.current?.api?.getRenderedNodes().length && params.request.rowGroupCols.length
                && JSON.stringify(UnitRateIdRowDataCtx.rowData) !== JSON.stringify(params.request.rowGroupCols))
                || ((allRowsCount) >= rowDataClientSide.length && hasValueColsChanged))) {
                    unitRateIdRef.current = '';
                    UnitRateIdRowDataCtx.setUnitRateId('');
                    UnitRateIdRowDataCtx.setLevelsData({});
                    UnitRateIdRowDataCtx.setRowData([]);
                    UnitRateIdRowDataCtx.setValueColsData([]);
                    setCanRenderClientSide(() => false);
                }
                const totalRows = UnitRateIdRowDataCtx.levelsData[currentLevel]?.total;
                params.successCallback(rowDataClientSide, totalRows || rowDataClientSide.length);
                setIsFetching(() => false);
                setCount((prev) => prev - 1);
            } else if (canRender
            // && !canRenderClientSide
                && projectCtx?.projectCaseData
                && params?.request?.rowGroupCols
                && params?.request?.groupKeys
                && searchText === debouncingValue
                && debouncedText === stateText
            ) {
                const sortingModel = params.request.sortModel.map((col) => ({ colName: col.colId, sort: col.sort }));
                const valuePivotCheck = (params?.request?.pivotMode
                    ? params?.request?.valueCols
                    : []);
                const valueColModel = (!params.request.rowGroupCols.length
                    ? valuePivotCheck
                    : params?.request?.valueCols);
                const query = estimateQueries.GET_ESTIMATE_DETAILS(
                    params?.request?.startRow || 0,
                    100,
                    userCtx?.user?.default_org_id || '',
                    selectedProject?.id || '',
                    selectedProject?.version_id || '',
                    estimateFilterId || '',
                    estimateFilterGCoaId || '',
                    estimateFilterWbsId || '',
                    debouncingValue,
                    filters,
                    JSON.stringify(sortingModel),
                    JSON.stringify(params.request.rowGroupCols),
                    JSON.stringify(params.request.groupKeys),
                    (1 / (exchangeRate || 1)),
                    JSON.stringify(valueColModel),
                );
                if (debouncingValue !== searchRef.current
                || rowGroupRef.current !== params.request.rowGroupCols) levelsRef.current = {};
                searchRef.current = debouncingValue;
                rowGroupRef.current = params.request.rowGroupCols;
                const graphQLClient = gqlConfig('/list');
                setIsFetching(() => true);
                if (clearAssociationRef.current === true && params.request.rowGroupCols.length === 0) {
                    setCount((prev) => prev - 1);
                    return;
                }
                clearAssociationRef.current = false;
                fetchData(graphQLClient, query, {}).then((unknownResponse: unknown) => {
                    const response = unknownResponse as ResponseEstimateDetail;
                    const attrData = addColsForAttr(response?.estimate_detail?.data as Data);
                    const totalValue = attrData?.total;
                    if (totalValue) {
                        const labourHours = totalValue?.total_man_hours;
                        const pEquipCost = totalValue?.equipment_cost;
                        const subcontractCost = totalValue?.subcontract_cost;
                        const footerValue = [{
                            // wbs: 'Selected(0)',
                            labour_hours: ` ${labourHours === null ? '-' : (+labourHours.toFixed(0)).toLocaleString()}`,
                            labour_cost: `${totalValue?.labour_cost === null ? '-'
                                : currenctFormat(totalValue?.labour_cost?.toFixed(0))}`,
                            construction_equip_cost: `${totalValue?.construction_equip_cost === null ? '-'
                                : currenctFormat(totalValue?.construction_equip_cost?.toFixed(0))}`,
                            material_cost: `${totalValue?.material_cost === null ? '-' : currenctFormat(totalValue?.material_cost?.toFixed(0))}`,
                            permanent_equip_cost: `${pEquipCost === null ? '-' : currenctFormat(pEquipCost?.toFixed(0))}`,
                            subcontract_cost: `${subcontractCost === null ? '-' : currenctFormat(subcontractCost?.toFixed(0))}`,
                            indirect_cost: `${totalValue?.indirect_cost === null ? '-' : currenctFormat(totalValue?.indirect_cost?.toFixed(0))}`,
                            total_cost: `${totalValue?.total_cost === null ? '-' : currenctFormat(totalValue?.total_cost?.toFixed(0))}`,
                        }];
                        setFooterData(JSON.stringify(footerValue));
                    } else {
                        setFooterData(null);
                    }
                    if (!canRenderClientSide) {
                        UnitRateIdRowDataCtx.setLevelsData({});
                        unitRateIdRef.current = '';
                        UnitRateIdRowDataCtx.setUnitRateId('');
                        UnitRateIdRowDataCtx.setValueColsData(params.request.valueCols);
                    }
                    const rowsData = attrData?.estimatedData && attrData?.estimatedData.length ? attrData?.estimatedData : [];
                    const totalRows = response?.estimate_detail?.pageInfo?.totalcount;
                    if (!levelsRef.current[currentLevel]) {
                        levelsRef.current[currentLevel] = {};
                    }
                    const dataEstimate = rowsData.map((item: EstimateType) => ({
                        ...item,
                        isExpanded: false,
                    }));
                    if ((levelsRef.current[currentLevel].values as EstimateTypeExpanded[])?.length > 99) {
                        if (levelsRef.current[currentLevel].values) {
                            levelsRef.current[currentLevel].values?.push(...dataEstimate);
                        } else {
                            levelsRef.current[currentLevel].values = dataEstimate;
                        }
                    } else {
                        levelsRef.current[currentLevel].values = dataEstimate;
                    }
                    UnitRateIdRowDataCtx.setRowData(params.request.rowGroupCols);
                    levelsRef.current[currentLevel].expanded = false;
                    levelsRef.current[currentLevel].total = totalRows as number;
                    params.successCallback(rowsData, totalRows || rowsData.length);
                    if (!rowsData.length) {
                        ref?.current?.api?.showNoRowsOverlay();
                    }
                    canRenderRef.current = false;
                }).catch((err: Error) => {
                    params.failCallback();
                    cont.showNotificationBar(err.message.substring(0, 60), 'error');
                }).finally(() => {
                    setIsFetching(() => false);
                    setCount((prev) => prev - 1);
                });

                if (JSON.stringify(params.request.rowGroupCols) !== rowGroupModel) setRowGroupModel(JSON.stringify(params.request.rowGroupCols));
                if (JSON.stringify(params.request.groupKeys) !== groupKeys) setGroupKeys(JSON.stringify(params.request.groupKeys));
            } else {
                params.failCallback();
                setIsFetching(() => false);
                setCount((prev) => prev - 1);
            }
        } else if (params?.request?.pivotMode || params?.request?.valueCols.length) {
            params.successCallback([], 0);
            ref?.current?.api?.showNoRowsOverlay();
            setIsFetching(() => false);
        }
    }, [filters, debouncingValue, searchText, debouncedText, stateText,
        canRender, canRenderClientSide, !estimateFilterId, !estimateFilterGCoaId, !estimateFilterWbsId]);

    const [newAttrCols, setNewAttrCols] = useState<ColumnDefsButWithSort[]>([]);

    const datasource: IServerSideDatasource = useMemo(() => ({
        getRows,
    }), [getRows]);

    const onFilterChanges = (event: FilterChangedEvent) => {
        if (filters !== JSON.stringify(event.api.getFilterModel())) {
            setCanRenderClientSide(() => false);
            setIsFetching(() => true);
        }
        setFilters(JSON.stringify(event.api.getFilterModel()));
        refreshSetFilters(event);
    };

    const onSortChanges = (event: SortChangedEvent) => {
        setCanRenderClientSide(() => false);
        const sortingModel = event.columnApi.getColumnState().reduce((acc: { colName: string; sort: string; }[], col) => {
            if (col.sort) {
                acc.push({ colName: col.colId, sort: col.sort });
            }
            return acc;
        }, []);
        setSortModel(JSON.stringify(sortingModel));
    };

    const onRowGroupChanges = (event: ColumnRowGroupChangedEvent) => {
        if (event?.columns && !event.columns.length) {
            ref?.current?.columnApi?.setColumnVisible('description', true);
        } else if (event?.columns && event.columns.length) {
            ref?.current?.columnApi?.setColumnVisible('description', false);
        }
    };

    const groupsOpenByDefault = (
        params: IsServerSideGroupOpenByDefaultParams,
    ) => {
        if (!(estimateFilterId) || estimateFilterWbsId || estimateFilterGCoaId) return false;
        return params.rowNode.level >= 0 && params.rowNode.level < ((JSON.parse(rowGroupModel) as unknown[]).length - 1);
    };
    const groupsOpenByDefaultForAssociation = (
        params: IsServerSideGroupOpenByDefaultParams,
    ) => (params.data as EstimateTypeExpanded).isExpanded;

    useEffect(() => {
        if (ref && ref?.current) {
            if (debouncingValue) {
                setSearchText(() => '');
                setTimeout(() => {
                    ref?.current?.columnApi?.resetColumnState();
                    ref?.current?.api?.setFilterModel(null);
                });
            } else {
                setStateText((prev) => (prev === 'false' ? 'true' : 'false'));
                setTimeout(() => {
                    ref?.current?.columnApi?.resetColumnState();
                    if (filters && filters !== '{}') {
                        ref?.current?.api?.setFilterModel(null);
                    } else {
                        ref?.current?.api?.setServerSideDatasource(datasource);
                    }
                });
            }
        }
    }, [exchangeRate]);

    useEffect(() => {
        ref?.current?.api?.deselectAll();
    }, [exchangeRate, debouncingValue, filters, sortModel]);

    const wbsparent1 = {
        field: 'wbs.first_parent',
        type: 'string',
        enableValue: false,
        initialWidth: 130,
        headerName: 'WBS Parent 1',
        initialRowGroup: true,
        suppressMenu: true,
        sortable: false,
        cellRenderer: rowCellRenderer,
        initialHide: true,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: getSetFilterValues,
            refreshValuesOnOpen: true,
        },
    };
    const wbsparent2 = {
        field: 'wbs.second_parent',
        type: 'string',
        enableValue: false,
        initialWidth: 130,
        headerName: 'WBS Parent 2',
        initialRowGroup: true,
        suppressMenu: true,
        sortable: false,
        cellRenderer: rowCellRenderer,
        initialHide: true,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: getSetFilterValues,
            refreshValuesOnOpen: true,
        },
    };
    const wbsparent3 = {
        field: 'wbs.third_parent',
        type: 'string',
        enableValue: false,
        initialWidth: 130,
        headerName: 'WBS Parent 3',
        initialRowGroup: true,
        suppressMenu: true,
        sortable: false,
        cellRenderer: rowCellRenderer,
        initialHide: true,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: getSetFilterValues,
            refreshValuesOnOpen: true,
        },
    };
    const wbsparent4 = {
        field: 'wbs.forth_parent',
        type: 'string',
        enableValue: false,
        initialWidth: 160,
        headerName: 'WBS Parent 4',
        cellRenderer: rowCellRenderer,
        initialHide: true,
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: getSetFilterValues,
            refreshValuesOnOpen: true,
        },
    };
    const wbsparent5 = {
        field: 'wbs.fifth_parent',
        type: 'string',
        enableValue: false,
        initialWidth: 160,
        headerName: 'WBS Parent 5',
        cellRenderer: rowCellRenderer,
        initialHide: true,
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: getSetFilterValues,
            refreshValuesOnOpen: true,
        },
    };
    const selfwbs = {
        field: 'wbs.self_code_desc',
        type: 'string',
        enableValue: false,
        initialWidth: 130,
        headerName: 'WBS Self Desc',
        initialRowGroup: true,
        suppressMenu: true,
        sortable: false,
        cellRenderer: rowCellRenderer,
        initialHide: true,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
    };
    const coaParent1 = {
        field: 'coa.first_parent',
        type: 'string',
        enableValue: false,
        initialWidth: 130,
        headerName: 'COA Parent 1',
        initialRowGroup: true,
        suppressMenu: true,
        sortable: false,
        cellRenderer: rowCellRenderer,
        initialHide: true,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: getSetFilterValues,
            refreshValuesOnOpen: true,
        },
    };
    const coaParent2 = {
        field: 'coa.second_parent',
        type: 'string',
        enableValue: false,
        initialWidth: 160,
        headerName: 'COA Parent 2',
        cellRenderer: rowCellRenderer,
        initialHide: true,
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: getSetFilterValues,
            refreshValuesOnOpen: true,
        },
    };
    const coaParent3 = {
        field: 'coa.third_parent',
        type: 'string',
        enableValue: false,
        initialWidth: 160,
        headerName: 'COA Parent 3',
        cellRenderer: rowCellRenderer,
        initialHide: true,
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: getSetFilterValues,
            refreshValuesOnOpen: true,
        },
    };
    const coaParent4 = {
        field: 'coa.forth_parent',
        type: 'string',
        enableValue: false,
        initialWidth: 160,
        headerName: 'COA Parent 4',
        cellRenderer: rowCellRenderer,
        initialHide: true,
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: getSetFilterValues,
            refreshValuesOnOpen: true,
        },
    };
    const coaParent5 = {
        field: 'coa.fifth_parent',
        type: 'string',
        enableValue: false,
        initialWidth: 160,
        headerName: 'COA Parent 5',
        cellRenderer: rowCellRenderer,
        initialHide: true,
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            suppressCount: true,
        },
        suppressToolPanel: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: getSetFilterValues,
            refreshValuesOnOpen: true,
        },
    };

    useEffect(() => {
        fetchRef.current = isFetching;
        const colsToPush: ColumnDefsButWithSort[] = [];
        const casedata = projectCtx?.projectCaseData;
        const projectLevel = getProjectLevel([
            selectedProject?.wbs1,
            selectedProject?.wbs2,
            selectedProject?.wbs3,
            selectedProject?.wbs4,
            selectedProject?.wbs5,
            selectedProject?.wbs6,
        ]);
        const coaProjectLevel = getProjectLevel([
            selectedProject?.coa1,
            selectedProject?.coa2,
            selectedProject?.coa3,
            selectedProject?.coa4,
            selectedProject?.coa5,
            selectedProject?.coa6,
        ]);

        if (casedata && casedata.length > 1) {
            colsToPush.push({
                field: 'case.name',
                type: 'string',
                enableValue: false,
                initialWidth: 130,
                headerName: 'Case Desc',
                cellRenderer: rowCellRenderer,
                initialHide: true,
                initialRowGroup: true,
                suppressMenu: true,
                sortable: false,
                cellRendererParams: {
                    suppressCount: true,
                },
                suppressToolPanel: true,
            });
        } else {
            colsToPush.push({
                field: 'case.name',
                type: 'string',
                enableValue: false,
                initialWidth: 130,
                headerName: 'Case Desc',
                cellRenderer: rowCellRenderer,
                initialHide: true,
            });
        }

        switch (projectLevel) {
        case 1:
            colsToPush.push(selfwbs);
            break;
        case 2:
            colsToPush.push(wbsparent1, selfwbs);
            break;
        case 3:
            colsToPush.push(
                wbsparent1,
                wbsparent2,
                selfwbs,
            );
            break;
        case 4:
            colsToPush.push(
                wbsparent1,
                wbsparent2,
                wbsparent3,
                selfwbs,
            );
            break;
        case 5:
            colsToPush.push(
                wbsparent1,
                wbsparent2,
                wbsparent3,
                wbsparent4,
                selfwbs,
            );
            break;
        default:
            colsToPush.push(
                wbsparent1,
                wbsparent2,
                wbsparent3,
                wbsparent4,
                wbsparent5,
                selfwbs,
            );
        }
        switch (coaProjectLevel) {
        case 1:
            colsToPush.push(coaParent1);
            break;
        case 2:
            colsToPush.push(coaParent1);
            break;
        case 3:
            colsToPush.push(coaParent1, coaParent2);
            break;
        case 4:
            colsToPush.push(coaParent1, coaParent2, coaParent3);
            break;
        case 5:
            colsToPush.push(coaParent1, coaParent2, coaParent3, coaParent4);
            break;
        default:
            colsToPush.push(coaParent1, coaParent2, coaParent3, coaParent4, coaParent5);
        }
        if (estimateFilterId || estimateFilterWbsId || estimateFilterGCoaId) {
            const colsForWbs = colsToPush.map((col: ColumnDefsButWithSort) => {
                const column = JSON.parse(JSON.stringify(col)) as ColumnDefsButWithSort;
                if (col?.filterParams) {
                    column.filterParams = col?.filterParams;
                }
                if (col.field !== 'wbs.self_code_desc' && col.field !== 'coa.first_parent') {
                    column.initialRowGroup = false;
                }
                return column;
            });
            columnDefs.push(...colsForWbs, ...newAttrCols);
        } else {
            columnDefs.push(...colsToPush, ...newAttrCols);
        }

        const rowDataFieldsSet = new Set(UnitRateIdRowDataCtx.rowData.map((rowdata) => rowdata.field as string));
        const updatedColumnDefs = UnitRateIdRowDataCtx.unitRateId && navigation === 'POP' && canRenderClientSide
            ? columnDefs.map((item) => ({ ...item, initialRowGroup: rowDataFieldsSet.has(item.field) }))
            : columnDefs;

        setTimeout(() => {
            setNewColumnDefs(updatedColumnDefs);
        });
        if (!isFetching && data?.estimatedData && data?.estimatedData?.length) {
            if (data?.estimatedData?.length > 0 && data?.estimatedData[0]?.labels) {
                Object.entries(data.estimatedData[0].labels).forEach(([key]) => {
                    if (key !== 'null' && !(columnDefs.some((col) => col.field === key))) {
                        const obj = {
                            field: key,
                            headerName: `${key}`,
                            cellRenderer: (params: CellRendererType) => {
                                const { value } = params;
                                // Condition for row group aggregation when pivot mode is not active
                                if (rowGroupCellRendererCheck(params)) {
                                    return '';
                                }
                                if (value === undefined) return '';
                                if (value === null) return '-';
                                return !Number.isNaN(Number(value)) ? formatCurrency((Number(value) || 0).toFixed(2)) : value;
                            },
                            initialWidth: 150,
                            type: 'string',
                            enableValue: false,
                            initialHide: true,
                            sortable: true,
                            enableRowGroup: false,
                            colId: `attr__${key}`,
                            suppressMenu: true,
                        };
                        columnDefs.push(obj);
                        newAttrCols.push(obj);
                    }
                });
                setNewAttrCols(newAttrCols);

                const updatedColumnDef = UnitRateIdRowDataCtx.unitRateId && navigation === 'POP' && canRenderClientSide
                    ? columnDefs.map((item) => ({ ...item, initialRowGroup: rowDataFieldsSet.has(item.field) }))
                    : columnDefs;

                setTimeout(() => {
                    setNewColumnDefs(updatedColumnDef);
                });
            }
        }
    }, [isFetching, count]);

    const [loader, setLoader] = useState<boolean>(true);
    useEffect(() => {
        countRef.current = count;
        if (count === 0) {
            setLoader(() => false);
        } else {
            setLoader(() => true);
        }
    }, [count]);

    const handleClear = () => {
        window.history.replaceState({}, '');
        setEstimateFilterId('');
        setEstimateFilterGCoaId('');
        setEstimateFilterWbsId('');
        estimateFilterIdRef.current = '';
        estimateFilterWbsIdRef.current = '';
        estimateFilterGCoaIdRef.current = '';
        clearAssociationRef.current = true;
        setCanRenderClientSide(() => false);
    };

    useEffect(() => {
        if (!(estimateFilterId || estimateFilterGCoaId || estimateFilterWbsId)) {
            setTimeout(() => {
                setNewColumnDefs(columnDefs);
                ref?.current?.api?.setServerSideDatasource(datasource);
            });
        }
    }, [estimateFilterId, estimateFilterGCoaId, estimateFilterWbsId]);
    // const sortedData = rowData.sort((a, b) => +(b.wbs.code) - +(a.wbs.code));

    const autoGroupColumnDef = useMemo(() => ({
        headerName: 'Description',
        field: 'description',
        minWidth: 200,
        resizable: true,
        sortable: false,
        suppressMenu: true,
        suppressMovable: true,
        cellClass: classes.singleColumnGroup,
        cellStyle: {
            padding: '0 0 0 10px',
        },
        cellRendererParams: {
            suppressPadding: true,
            innerRenderer: CustomGroupRowRenderer,
            handleLink,
        },
    }), []);

    return (
        <Box className={(estimateFilterId || estimateFilterWbsId || estimateFilterGCoaId) ? classes.mainEstmate : classes.mainEstmate2}>
            {/* <Loader loading={!canRenderClientSide && loader} /> */}
            <Loader loading={loader} />
            <EstimateHeader
                canRenderClientSide={canRenderClientSide}
                setSearchText={setSearchText}
                searchText={searchText}
                gridLayoutData={estimateGridLayoutState}
                setGridView={setGridView}
                gridView={gridView}
                deleteMutate={deleteMutate}
                resetMultipleEstimateMutate={resetMultipleEstimateMutate}
                resetDefaultViewEstimateMutate={resetDefaultViewEstimateMutate}
                isResetMultipleEstimateLoading={isResetMultipleEstimateLoading}
            />
            { (estimateFilterId || estimateFilterGCoaId || estimateFilterWbsId)
            && (
                <Box className={classes.filterDesc}>
                    <span>Data filtered by: </span>
                    <span>
                        {code || gcoaCode || industryCode}
                        {' '}
                        -
                        {' '}
                        {description || gcoaDescription || industryDescription}
                    </span>
                    <button type="button" onClick={() => handleClear()}>Clear</button>
                </Box>
            )}
            <Box>
                <Box className={loader ? classes.opacityBlur : ''}>
                    <AgGridComponent
                        gridRef={ref}
                        gridView={gridView}
                        autoGroupColumnDefs={autoGroupColumnDef}
                        isEstimateResetLoading={isEstimateResetLoading}
                        setLayoutName={setLayoutName}
                        setGridState={setGridState}
                        gridState={gridState}
                        handleOpen={handleOpen}
                        saveEstimateGridLoading={saveEstimateGridLoading}
                        columnDefs={newColumnDefs}
                        setGridLayoutData={setGridLayoutData}
                        // rowData={rowData}
                        changeSortingValue={() => { }}
                        pinnedBottomRowData={footerData ? JSON.parse(footerData) : undefined}
                        // quickFilterText={searchText}
                        isPinnable
                        isGroupable
                        isRangeSelectable
                        isExportEnabled
                        isClipboardEnabled
                        isToolPanelsEnabled
                        isStatusBarEnabled
                        isColumnMovable
                        moduleName="estimate"
                        defaultExpanded={6}
                        isRangeHandle
                        groupDisplayType="singleColumn"
                        // customGroupRowRenderer={CustomGroupRowRenderer}
                        colFormat={['total_cost', 'total_unit_cost', 'unit_labour_cost',
                            'unit_consequip_cost', 'unit_material_cost', 'unit_pequipment_cost',
                            'unit_subcontract_cost', 'unit_indirect_cost', 'labour_cost', 'construction_equip_cost',
                            'material_cost', 'permanent_equip_cost', 'subcontract_cost', 'indirect_cost']}
                        disableResizable
                        datasource={datasource}
                        onFilterChanges={onFilterChanges}
                        onSortChanges={onSortChanges}
                        onFilterOpens={onFilterOpens}
                        onColumnRowGroupChanges={onRowGroupChanges}
                        loadingRenderer={loadingRenderer}
                        groupsOpenByDefault={canRenderClientSide ? groupsOpenByDefaultForAssociation : groupsOpenByDefault}
                        setSearchText={setSearchText}
                        isMultiFilterRequired={false}
                        onRowGroupOpened={onRowGroupOpened}
                        estimateGridLayoutState={estimateGridLayoutState}
                        estimateRefetch={refetch}
                        isFetchingEstimate={isFetchingEstimate}
                        rowSelection="single"
                        setCanRenderClientSide={setCanRenderClientSide}
                    />
                </Box>
            </Box>
            <SaveGridLayout
                open={open}
                setGridView={setGridView}
                gridView={gridView}
                setOpen={setOpen}
                gridState={gridState}
                handleOpen={handleOpen}
                mutate={mutate}
                setLayoutName={setLayoutName}
                layoutName={layoutName}
                pivotMode={isPivotActive}
                gridLayoutData={estimateGridLayoutState}
                setCanRenderClientSide={setCanRenderClientSide}
            />
        </Box>
    );
}
