import React, {
    Fragment, useContext, useEffect, useMemo, useState, useRef,
} from 'react';
import IndustriesStyle from './industries.style';
import IndustriesHeader from '../../components/IndustriesHeader';
import { Box, Button } from '@mui/material';
import PageUnavailable from '../../../ProjectSettings';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import { UserContext } from '../../../../store/context/userContext';
import { GET_ALL_INDUSTRY, DELETE_INDUSTRIES } from '../../../../queries/industries';
import AgGridComponent from '../../../../components/agGridTableComponent';
import { CellRendererDetailsType, CellRendererType } from '../../../../types/AgGridTypes';
import Loader from '../../../../components/Loader';
import Pagination from '../../../../components/paginationTable';
import { DEFAULT_PAGE_SIZE, ROLES } from '../../../../constants';
import { useFormatCurrency, formatCurr } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';
import { ProjectContext } from '../../../../store/context/projectContext';
import { GridOptions, RowHeightParams, ValueFormatterParams } from 'ag-grid-community';
import NotificationBar from '../../../../components/NotificationBar';
import DeleteRowModel from '../../../ProjectsListing/Components/DeleteRowModel';
import IndustriesType from '../../../../types/IndustriesType';
import { AgGridReact } from 'ag-grid-react';
import NotificationBarType from '../../../../types/NotificationBarType';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import { useUserRole } from '../../../../hooks/useUserRole';
import ImportOptionsModel from '../../../ProjectsListing/Components/ImportOptionsModel';
import { OutletContext, QueryErrorResponse } from '../../../../types/CommoditiesListType';
import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import GetAllIndustry from '../../../../types/GetAllIndurtiesType';
import { OrganisationContext } from '../../../../store/context/organisationContext';
import { getIndustryLevel } from '../../../../helper/ProjectLevel';
import PageInfoType from '../../../../types/PageInfoType';
import InvitationType from '../../../../types/InvitationTypes';
import { dateFormat } from '../../../../helper/DateFormat';
import { PATH_CMFR } from '../../../../Routes/path';
import ProjectListType from '../../../../types/ProjectListType';

interface CellRendererTyp {
   data: {
    projects: string[];
    project_count: number,
   }
    successCallback: (projects: string[]) => void,
    isProjectCountGreaterThanZero: (projects: string[]) => void,
}

interface IndData {
    all_industries?: {
        data?: GetAllIndustry[] | undefined;
        pageInfo?: PageInfoType;
    };
}

function CustomGroupRowRenderer(params: ValueFormatterParams) {
    const { node } = params;
    if (node) {
        const rowNode = node;
        const selectColor = () => {
            let color;
            switch (rowNode.field) {
            case 'first_parent':
                color = '#88B0FF';
                break;
            case 'second_parent':
                color = '#AECAFF';
                break;
            case 'self_code_desc':
                color = '#C1D6FF';
                break;
            default:
                color = '#F8F8F8';
                break;
            }
            return color;
        };

        const style = {
            height: '100%',
            backgroundColor: selectColor(),
            fontSize: '12px',
            fontWeight: 700,
            color: '#000',
            lineHeight: '27px',
            paddingLeft: '22px',
        };

        return (
            <div style={style}>
                <span style={style}>
                    <span>
                        {rowNode.key}
                    </span>
                </span>
            </div>
        );
    }
}

export default function Industries() {
    const classes = IndustriesStyle();
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const [data, setData] = useState(!!userCtx?.user?.default_org_id);
    const [hideUnusedFlag, setHideUnusedFlag] = useState(true);
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [searchText, setSearchText] = useState('');
    const formatCurrency = useFormatCurrency();
    const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];
    const gridRef = useRef<AgGridReact<IndustriesType>>(null);
    const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
    const [notificationDetails, setNotificationDetails] = useState<NotificationBarType>({
        open: false,
        type: 'success',
        message: '',
    });
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const loggedInUserRole = useUserRole();
    const [openim, setOpenImport] = useState(false);
    const [footerData, setFooterData] = useState({ data: [{ code: 'Selected Rows: 0/0' }], totalRows: 0 });
    const cont: OutletContext = useOutletContext();
    const [industriesTableData, setIndustriesTableData] = useState<IndData>();
    const [industryLevel, setIndustryLevel] = useState(0);
    const [rowData, setRowData] = useState<GetAllIndustry[]>([]);
    const masterRefRowData = useRef<GetAllIndustry[] | null>(null);

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0]
            ? response.errors[0].message
            : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const handleSuccess = (apidata: InvitationType) => {
        if (apidata.all_industries?.data.length === 0) {
            setIndustryLevel(0);
            setRowData([]);
            setFooterData({ data: [{ code: 'Selected Rows: 0/0' }], totalRows: 0 });
        }
        const updateIndustryData = { ...apidata };
        masterRefRowData.current = updateIndustryData?.all_industries?.data || null;
        setIndustriesTableData(updateIndustryData);
    };

    const { data: industryData, isFetching, refetch } = useGQLQuery(
        `GetIndustries-${userCtx?.user?.default_org_id || ''}`,
        GET_ALL_INDUSTRY(
            0,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            hideUnusedFlag,
        ),
        {},
        {
            enabled: data,
            onError: handleApiError,
            cacheTime: 0,
            onSuccess: handleSuccess,
        },
    );

    useEffect(() => {
        const industiesData = industriesTableData?.all_industries;
        const tableData = industiesData?.data;
        if (tableData && tableData.length) {
            const row1 = tableData[0];
            const level = getIndustryLevel([
                row1.code1, row1.code2, row1.code3, row1.code4, row1.code5, row1.code6,
            ]);
            setIndustryLevel(level);
            setRowData(tableData);
            setFooterData({ data: [{ code: `Selected Rows: 0/${tableData.length}` }], totalRows: tableData.length });
        }
    }, [industriesTableData]);

    useEffect(() => {
        if (data) {
            refetch();
        }
    }, [pageSkipValue, hideUnusedFlag]);

    type IndustriesResponseType = {
        deleteIndustries: {
            error: string,
            error_code: number,
            message: string
        }
    };

    const handleDeleteSuccess = (res: IndustriesResponseType) => {
        setIndustriesTableData({});
        setRowData([]);
        setIndustryLevel(0);
        refetch();
        setSelectedIndustries([]);
        setNotificationDetails({
            open: true,
            message: res?.deleteIndustries?.message,
            type: 'success',
        });
    };

    const handleDelete = () => {
        setOpenDeleteModel(true);
    };

    const { mutate, isLoading } = useGQLMutation(
        'DeleteIndustries',
        DELETE_INDUSTRIES(userCtx?.user?.default_org_id || '', JSON.stringify(selectedIndustries)),
        {
            onSuccess: handleDeleteSuccess,
            onError: handleApiError,
        },
        '/list',
    );

    const onSelectionChanged = () => {
        if (gridRef.current) {
            const gridApi = gridRef.current.api;
            const selectedRows = gridApi?.getSelectedRows();
            if (selectedRows) {
                const updatedFooterData = {
                    ...footerData,
                    data: [
                        {
                            ...footerData.data[0],
                            code: `Selected Rows: ${selectedRows.length}/${footerData.totalRows}`,
                        },
                    ],
                };
                setFooterData(updatedFooterData);
            }
            if (selectedRows && selectedRows.length) {
                const selectedIndustriesIds = selectedRows.map((org) => org.id);
                setSelectedIndustries(selectedIndustriesIds);
            } else {
                setSelectedIndustries([]);
            }
        }
    };

    const onDeleteConfirmation = () => {
        mutate({});
        setOpenDeleteModel(false);
    };

    const customCellRenderer = (params: CellRendererType) => {
        const paramsData = params?.data;
        const colName = params.colDef.field;
        if (params.value != null) {
            switch (colName) {
            case 'description':
                if (params?.data?.level === 1) {
                    return (
                        <span
                            className={industryLevel === 6 ? classes.headingText : classes.boldText}
                        >
                            {params.value}
                        </span>
                    );
                }
                if (params?.data?.level === 2) {
                    return (
                        <span
                            className={industryLevel === 2 ? classes.normalText : classes.boldText}
                        >
                            &emsp;
                            {params.value}
                        </span>
                    );
                }
                if (params?.data?.level === 3) {
                    return (
                        <span
                            className={industryLevel <= 3 ? classes.normalText : classes.boldText}
                        >
                            &emsp;&emsp;
                            {params.value}
                        </span>
                    );
                }
                if (params?.data?.level === 4) {
                    return (
                        <span
                            className={industryLevel <= 4 ? classes.normalText : classes.boldText}
                        >
                            &emsp;&emsp;&emsp;
                            {params.value}
                        </span>
                    );
                }
                if (params?.data?.level === 5) {
                    return (
                        <span
                            className={industryLevel <= 5 ? classes.normalText : classes.boldText}
                        >
                            &emsp;&emsp;&emsp;&emsp;
                            {params.value}
                        </span>
                    );
                }
                if (params?.data?.level === 6) {
                    return (
                        <span className={classes.normalText}>
                            &emsp;&emsp;&emsp;&emsp;&emsp;
                            {params.value}
                        </span>
                    );
                }
                break;
            case 'total_cost':
                if (!paramsData && params.value) {
                    return `$${formatCurr(+((+((+params.value)).toFixed(0))), 'en-US')}`;
                }
                return paramsData?.project_count > 0 && paramsData?.projects ? `$${formatCurr(+((+((+params.value)).toFixed(0))), 'en-US')}` : '-';
            case 'project_count':
                if (!paramsData && params.value) {
                    return `${params.value}`;
                }
                return params?.data?.project_count > 0 && params?.data?.projects ? `${params.value}` : '-';
            case 'unit':
                return params?.data?.unit ? `${params.value}` : '-';
            case 'attr_label':
                return params?.data?.attr_label ? `${params.value}` : '-';
            case 'capacity':
                return params?.data?.capacity > 0 ? `${formatCurr(((+params.value).toFixed(2)), 'en-US')}` : '-';
            case 'base_date':
                return dateFormat(params.value, 'yyyy');
            default:
                return '-';
            }
        }
        if (params.value === null) return '-';
        return '';
    };

    const tableNameCellRenderer = (params: CellRendererType) => {
        const colName = params.colDef.field;
        if (params.value != null) {
            switch (colName) {
            case 'total_cost':
                return `$${formatCurr(+((+params.value).toFixed(0)), 'en-US')}`;
            case 'cost':
                return params.data.unit === '%'
                    ? `${formatCurr((Number(Number(+params.value) * 100).toFixed(2)), 'en-US')}%`
                    : `$${formatCurr(((+params.value).toFixed(2)), 'en-US')}`;
            default:
                return params.value;
            }
        }
        if (params.value === null) return '-';
        return '';
    };

    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    // const rowCellRenderer = (params: CellRendererType) => {
    //     const { value } = params;
    //     const colName = params.colDef.field;
    //     if (value != null) {
    //         if (params?.node?.rowPinned) {
    //             return (
    //                 <span>
    //                     {params?.value.toLocaleString()}
    //                 </span>
    //             );
    //         }
    //         switch (colName) {
    //         case 'total_cost':
    //         case 'total_unit_cost':
    //             return formatCurrency(value);
    //         case 'quantity':
    //             return value && formatCurr(+params.value.toFixed(0), local);
    //         default:
    //             return value;
    //         }
    //     } else if (value === null) {
    //         return '-';
    //     }
    //     return '';
    // };

    // const totalPages = 1;
    // const industiesData = industryData?.all_industries;
    // const tableData = industiesData?.data;
    // const industriesTableData = tableData && tableData.length ? tableData : [];
    // useEffect(() => {
    //     const total = industriesTableData.length;
    //     setFooterData({ data: [{ code: `Selected Rows: 0/${total}` }], totalRows: total });
    // }, [industriesTableData]);

    const columnDefs = [
        {
            field: 'row_expand',
            minWidth: 40,
            maxWidth: 40,
            headerName: '',
            cellRenderer: 'agGroupCellRenderer',
            sortable: false,
        },
        // {
        //     field: 'first_parent',
        //     type: 'number',
        //     initialWidth: 130,
        //     headerName: 'Parent 1',
        //     cellRenderer: rowCellRenderer,
        //     hide: true,
        //     // rowGroup: true,
        //     cellRendererParams: {
        //         suppressCount: true,
        //     },
        //     suppressToolPanel: true,
        // },
        // {
        //     field: 'second_parent',
        //     type: 'number',
        //     initialWidth: 130,
        //     headerName: 'Parent 2',
        //     // rowGroup: true,
        //     cellRenderer: rowCellRenderer,
        //     hide: true,
        //     cellRendererParams: {
        //         suppressCount: true,
        //     },
        //     suppressToolPanel: true,
        // },
        // {
        //     field: 'self_code_desc',
        //     type: 'number',
        //     initialWidth: 130,
        //     headerName: 'Industries Self Desc',
        //     // rowGroup: true,
        //     cellRenderer: rowCellRenderer,
        //     hide: true,
        //     cellRendererParams: {
        //         suppressCount: true,
        //     },
        //     suppressToolPanel: true,
        // },
        {
            field: 'code',
            initialWidth: 150,
            headerName: 'Code',
            type: 'number',
            sort: 'asc',
        },
        {
            field: 'description',
            initialWidth: 350,
            headerName: 'Description',
            cellRenderer: customCellRenderer,
            type: 'string',
            flex: 1.5,
        },
        {
            field: 'attr_label',
            initialWidth: 150,
            cellRenderer: customCellRenderer,
            headerName: 'Attribute',
            type: 'string',
        },
        {
            field: 'unit',
            initialWidth: 130,
            headerName: 'Unit ',
            cellRenderer: customCellRenderer,
            type: 'string',
        },
        {
            field: 'project_count',
            initialWidth: 130,
            headerName: 'Projects',
            cellRenderer: customCellRenderer,
            type: 'string',
        },
        {
            field: 'total_cost',
            initialWidth: 180,
            headerName: 'Total Cost',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
    ];

    const isProjectCountGreaterThanZero = (params: CellRendererTyp) => params.data.project_count > 0;

    const navigate = useNavigate();
    const handleLink = (project:ProjectListType) => {
        const industryForProject = masterRefRowData.current?.find((indstry) => indstry.id === project?.curr_gwbs_id);
        projectCtx?.setProject(project);
        navigate(
            PATH_CMFR.capex.estimate,
            {
                state: {
                    wbsId: project?.curr_gwbs_id,
                    industryCode: industryForProject?.code,
                    industryDescription: industryForProject?.description,
                },
            },
        );
    };

    const nameCellRenderer = (params: CellRendererDetailsType) => (
        <Link
            to="/"
            onClick={(e) => {
                e.preventDefault();
                handleLink(params.data);
            }}
            style={{ color: '#007DFF' }}
        >
            {params.value}
        </Link>
    );

    const detailCellRendererParams = useMemo(() => ({
        detailGridOptions: {
            columnDefs: [
                {
                    field: 'consultant_number', headerName: 'Project Number', initialWidth: 115, lockVisible: true,
                },
                {
                    field: 'name', headerName: 'Name', initialWidth: 150, cellRendererFramework: nameCellRenderer,
                },
                {
                    field: 'estimate_type', headerName: 'Estimate Type', initialWidth: 140,
                },
                {
                    field: 'country_name', headerName: 'Country', initialWidth: 150,
                },
                {
                    field: 'region', headerName: 'Region', initialWidth: 150,
                },
                {
                    field: 'base_date', headerName: 'Base Date', initialWidth: 130, cellRenderer: customCellRenderer,
                },
                {
                    field: 'owner', headerName: 'Owner', initialWidth: 150,
                },
                {
                    field: 'description', headerName: 'Description', initialWidth: 150, cellRenderer: tableNameCellRenderer,
                },
                {
                    field: 'capacity', headerName: 'Value', type: 'numericColumn', initialWidth: 150, cellRenderer: customCellRenderer,
                },
                {
                    field: 'unit', headerName: 'Unit', initialWidth: 150, cellRenderer: customCellRenderer,
                },
                {
                    field: 'total_cost', headerName: 'Total Cost', initialWidth: 150, cellRenderer: tableNameCellRenderer, type: 'numericColumn',
                },
                {
                    field: 'cost', headerName: 'Unit Cost', initialWidth: 180, cellRenderer: tableNameCellRenderer, type: 'numericColumn',
                },
            ],
            defaultColDef: {
                // flex: 1,
                sortable: true,
                unSortIcon: true,
                suppressMenu: false,
                resizable: true,
                suppressMovable: true,
                filter: 'agMultiColumnFilter',
            },
            onRowDataUpdated: (params: GridOptions) => {
                const allColumns = params?.columnApi?.getAllColumns();
                if (allColumns) {
                    const allColumnIds = allColumns.map((column) => column.getId());
                    params?.columnApi?.autoSizeColumns(allColumnIds);
                }
            },
            getContextMenuItems: () => ['copy', 'copyWithHeaders', 'export'],
            getRowHeight: (params: RowHeightParams) => 27,
            overlayLoadingTemplate: 'No Suitable Rows To Show',
        },
        getDetailRowData(params: CellRendererTyp) {
            // if ('code' in params.data && 'description' in params.data) {
            //     masterRefRowData.current = { code: params?.data?.code as string || '', description: params.data.description as string || '' };
            // }
            params.successCallback(isProjectCountGreaterThanZero(params) ? params.data.projects : []);
        },
    }), []);

    if (!data) {
        return (<PageUnavailable />);
    }

    // const handleClickOpenImport = () => {
    //     setOpenImport(true);
    // };

    const userRole = ROLES.find((role) => role.id === loggedInUserRole);

    return (
        <Fragment>
            <IndustriesHeader
                setSearchText={setSearchText}
                searchText={searchText}
                setOpenImport={setOpenImport}
                hideUnusedFlag={hideUnusedFlag}
                setHideUnusedFlag={setHideUnusedFlag}
                selectedIndustries={selectedIndustries}
                handleDelete={handleDelete}
                isIndustriesLoading={isFetching}
            />
            <Box className={classes.tableSec}>
                <AgGridComponent
                    gridRef={gridRef}
                    onSelectionChanged={onSelectionChanged}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    changeSortingValue={() => {}}
                    isRangeSelectable
                    isGroupable
                    masterDetail
                    detailCellRendererParams={detailCellRendererParams}
                    isExportEnabled
                    isClipboardEnabled
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    isPinnable
                    moduleName="Industrie"
                    quickFilterText={searchText}
                    // colFormat={['total_cost']}
                    // customGroupRowRenderer={CustomGroupRowRenderer}
                    // defaultExpanded={3}
                    rowSelection="multiple"
                    setSearchText={setSearchText}
                    pinnedBottomRowData={footerData.data}
                    IndCommoRowBGStyle
                    IndCommoLevel={industryLevel}
                />
                <Loader loading={isFetching || isLoading} />
                <NotificationBar
                    open={notificationDetails.open}
                    setOpen={(newState) => setNotificationDetails({ ...notificationDetails, open: newState })}
                    type={notificationDetails.type}
                    message={notificationDetails.message}
                />
                {openDeleteModel && (
                    <DeleteRowModel
                        open={openDeleteModel}
                        setOpenDelRow={setOpenDeleteModel}
                        deleteAction={onDeleteConfirmation}
                        title="Industries"
                    />
                )}
                {openim && <ImportOptionsModel open={openim} setOpenImport={setOpenImport} refetchIndustries={refetch} />}
            </Box>
        </Fragment>
    );
}
